import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getUsers = (filter_url) => get(url.GET_USERS + filter_url)
const addNewUser = (data) => post(url.NEW_USER, data)
const updateUser = (data) => post(url.UPDATE_USER, data)
const deleteUser = (data) => post(url.DELETE_USER, data)

export {
    getUsers,
    addNewUser,
    updateUser,
    deleteUser
}
