import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken
//apply base url for axios
const API_URL = "https://apinoc-crm.qi.com.vn/api"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      let api = `${API_URL}/users/token/refresh/`;
      let config = {
        'Content-Type': 'application/json'
      }
      let data = { 'refresh': localStorage.getItem('refresh_token') };
      axiosApi
        .post(api, { ...data }, { ...config })
        .then(response => {
          localStorage.setItem("authUser", response.data.access)
        })
        .catch(err => {
          if (err.response.status === 401) {
            window.location.href = "/login";
          }
        })
    }
    return Promise.reject(error)
  }
)


export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config })
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
