import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getSubscriptions = () => get(url.GET_SUBSCRIPTIONS)
const getSubscriptionsByFilter = (param_filter) => get(url.GET_SUBSCRIPTIONS + param_filter)
const getConstraints = () => get(url.GET_CONSTRAINTS)
const addNewSubscription = (data) => post(url.NEW_SUBSCRIPTION, data)
const updateSubscription = (data) => post(url.UPDATE_SUBSCRIPTION, data)
const deleteSubscription = (data) => post(url.DELETE_SUBSCRIPTION, data)
const checkCustomerStatus = (data) => post(url.CHECK_SUBSCRIPTION_STATUS, data)
const cutSignalSubscription = (data) => post(url.CUT_SUBSCRIPTION, data)
const openSignalSubscription = (data) => post(url.OPEN_SUBSCRIPTION, data)


export {
    getSubscriptions,
    getSubscriptionsByFilter,
    addNewSubscription,
    updateSubscription,
    deleteSubscription,
    getConstraints,
    checkCustomerStatus,
    cutSignalSubscription,
    openSignalSubscription
}
