import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, Input as InputAnt, InputNumber, Spin } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalEdit,
    updateCutSignal,
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';

const Edit = (props) => {
    const { modalEdit, onCloseModalEdit, onEditCutSignal, dataRow } = props;

    const [fields, setFields] = useState({});

    const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm();

    const handleCancel = () => {
        onCloseModalEdit();
    };

    const onSubmit = data => {
        onEditCutSignal(data);
    };

    useEffect(() => {
        let row = {
            key: dataRow.id,
            id: dataRow.id,
            note: dataRow.note
        }
        reset(row);
    }, [reset, dataRow])

    return (
        <>
            <Modal isOpen={modalEdit.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={handleCancel}>{modalEdit.title}</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Ghi chú</label>
                                </Col>
                                <Col md={8}>
                                    <textarea
                                        aria-invalid={errors.note ? "true" : "false"}
                                        {...register('note')}
                                        className="form-control"
                                        placeholder='Ghi chú...'
                                        defaultValue={dataRow.note}
                                    ></textarea>
                                </Col>
                            </Row>
                        </Container>

                    </ModalBody>
                    <ModalFooter>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />

                        <ButtonAnt htmlType="submit" className='text-white' style={{ backgroundColor: "#6ECCAF" }}>
                            Cập nhật
                        </ButtonAnt>
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>

                </Form>
            </Modal>
        </>
    );
};
Edit.propTypes = {
    t: PropTypes.any,
    modalEdit: PropTypes.object,
    onCloseModalEdit: PropTypes.func,
    onEditCutSignal: PropTypes.func,
    dataRow: PropTypes.object,
}

const mapStateToProps = ({ helper, subscription }) => ({
    modalEdit: helper.modalEdit,
})

const mapDispatchToProps = dispatch => ({
    onCloseModalEdit: () => dispatch(closeModalEdit()),
    onEditCutSignal: (data) => dispatch(updateCutSignal(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Edit);