import {
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_SUCCESS,
  ADD_NEW_SUBSCRIPTION,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  DELETE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAIL,
  GET_CONSTRAINTS,
  GET_CONSTRAINTS_SUCCESS,
  GET_CONSTRAINTS_FAIL,
  GET_SUBSCRIPTIONS_BY_FILTER,
  SET_SUBSCRIPTION_FILTER
} from "./actionTypes"

export const getSubscriptions = () => ({
  type: GET_SUBSCRIPTIONS,
})

export const getSubscriptionsByFilter = param_filter => ({
  type: GET_SUBSCRIPTIONS_BY_FILTER,
  payload: param_filter
})

export const getSubscriptionsSuccess = subscriptions => ({
  type: GET_SUBSCRIPTIONS_SUCCESS,
  payload: subscriptions,
})

export const getSubscriptionsFail = error => ({
  type: GET_SUBSCRIPTIONS_FAIL,
  payload: error,
})

export const addNewSubscription = subscription => ({
  type: ADD_NEW_SUBSCRIPTION,
  payload: subscription,
})

export const addSubscriptionSuccess = subscription => ({
  type: ADD_SUBSCRIPTION_SUCCESS,
  payload: subscription,
})

export const addSubscriptionFail = error => ({
  type: ADD_SUBSCRIPTION_FAIL,
  payload: error,
})

export const updateSubscription = subscription => ({
  type: UPDATE_SUBSCRIPTION,
  payload: subscription,
})

export const updateSubscriptionSuccess = subscription => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
  payload: subscription,
})

export const updateSubscriptionFail = error => ({
  type: UPDATE_SUBSCRIPTION_FAIL,
  payload: error,
})

export const deleteSubscription = subscription => ({
  type: DELETE_SUBSCRIPTION,
  payload: subscription,
})

export const deleteSubscriptionSuccess = subscription => ({
  type: DELETE_SUBSCRIPTION_SUCCESS,
  payload: subscription,
})

export const deleteSubscriptionFail = error => ({
  type: DELETE_SUBSCRIPTION_FAIL,
  payload: error,
})

export const getConstraints = () => ({
  type: GET_CONSTRAINTS,
})

export const getConstraintsSuccess = constraints => ({
  type: GET_CONSTRAINTS_SUCCESS,
  payload: constraints,
})

export const getConstraintsFail = error => ({
  type: GET_CONSTRAINTS_FAIL,
  payload: error,
})

export const setSubscriptionFilter = param => ({
  type: SET_SUBSCRIPTION_FILTER,
  payload: param,
})