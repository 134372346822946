import { takeEvery, put, call, all, fork } from "redux-saga/effects"

// Calender Redux States
import {
  openModalAdd,
  closeModalAdd,
  openModalEdit,
  closeModalEdit,
  openModalDetail,
  closeModalDetail,
  openModalFilter,
  closeModalFilter,
  openModalUpload,
  closeModalUpload,
} from "./actions"

import {
  CHANGE_OPEN_MODAL_ADD,
  CHANGE_CLOSE_MODAL_ADD,
  CHANGE_OPEN_MODAL_EDIT,
  CHANGE_CLOSE_MODAL_EDIT,
  CHANGE_OPEN_MODAL_DETAIL,
  CHANGE_CLOSE_MODAL_DETAIL,
  CHANGE_OPEN_MODAL_FILTER,
  CHANGE_CLOSE_MODAL_FILTER,
  CHANGE_OPEN_MODAL_UPLOAD,
  CHANGE_CLOSE_MODAL_UPLOAD,
} from "./actionTypes"

// Modal add
function* changeOpenModalAdd({ payload: modal }) {
  try {
    yield put(openModalAdd(modal))
  } catch (error) { }
}

function* changeCloseModalAdd() {
  try {
    yield put(closeModalAdd())
  } catch (error) { }
}

// Modal edit
function* changeOpenModalEdit({ payload: modal }) {
  try {
    yield put(openModalEdit(modal))
  } catch (error) { }
}

function* changeCloseModalEdit() {
  try {
    yield put(closeModalEdit())
  } catch (error) { }
}

// Modal detail
function* changeOpenModalDetail({ payload: modal }) {
  try {
    yield put(openModalDetail(modal))
  } catch (error) { }
}

function* changeCloseModalDetail() {
  try {
    yield put(closeModalDetail())
  } catch (error) { }
}

// Modal detail
function* changeOpenModalFilter({ payload: modal }) {
  try {
    yield put(openModalFilter(modal))
  } catch (error) { }
}

function* changeCloseModalFilter() {
  try {
    yield put(closeModalFilter())
  } catch (error) { }
}

function* changeOpenModalUpload({ payload: modal }) {
  try {
    yield put(openModalUpload(modal))
  } catch (error) { }
}

function* changeCloseModalUpload() {
  try {
    yield put(closeModalUpload())
  } catch (error) { }
}

export function* watchChangeOpenModalAdd() {
  yield takeEvery(CHANGE_OPEN_MODAL_ADD, changeOpenModalAdd)
}

export function* watchChangeCloseModalAdd() {
  yield takeEvery(CHANGE_CLOSE_MODAL_ADD, changeCloseModalAdd)
}

export function* watchChangeOpenModalEdit() {
  yield takeEvery(CHANGE_OPEN_MODAL_EDIT, changeOpenModalEdit)
}

export function* watchChangeCloseModalEdit() {
  yield takeEvery(CHANGE_CLOSE_MODAL_EDIT, changeCloseModalEdit)
}

export function* watchChangeOpenModalDetail() {
  yield takeEvery(CHANGE_OPEN_MODAL_DETAIL, changeOpenModalDetail)
}

export function* watchChangeCloseModalDetail() {
  yield takeEvery(CHANGE_CLOSE_MODAL_DETAIL, changeCloseModalDetail)
}

export function* watchChangeOpenModalFilter() {
  yield takeEvery(CHANGE_OPEN_MODAL_FILTER, changeOpenModalFilter)
}

export function* watchChangeCloseModalFilter() {
  yield takeEvery(CHANGE_CLOSE_MODAL_FILTER, changeCloseModalFilter)
}

export function* watchChangeOpenModalUpload() {
  yield takeEvery(CHANGE_OPEN_MODAL_UPLOAD, changeOpenModalUpload)
}

export function* watchChangeCloseModalUpload() {
  yield takeEvery(CHANGE_CLOSE_MODAL_UPLOAD, changeCloseModalUpload)
}


function* helperSaga() {
  yield all([
    fork(watchChangeOpenModalAdd),
    fork(watchChangeCloseModalAdd),
    fork(watchChangeOpenModalEdit),
    fork(watchChangeCloseModalEdit),
    fork(watchChangeOpenModalDetail),
    fork(watchChangeCloseModalDetail),
    fork(watchChangeOpenModalFilter),
    fork(watchChangeCloseModalFilter),
    fork(watchChangeOpenModalUpload),
    fork(watchChangeCloseModalUpload),
  ])
}

export default helperSaga