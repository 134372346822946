import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    CardHeader
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react";

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png";

//i18n
import { withTranslation } from "react-i18next"
import UserTable from './UserTable';
import { Input as InputAnt, Button as ButtonAnt } from 'antd';


import {
    getUsers,
} from "../../store/actions"
import { connect } from "react-redux"
import { get } from 'lodash';
import { CHANGE_OPEN_MODAL_ADD } from 'store/helper/actionTypes';
import Add from "./Add"

const Users = props => {
    const [menu, setMenu] = useState(false)
    const { onGetUsers, onOpenModalAdd } = props
    const toggle = () => {
        setMenu(!menu)
    }

    useEffect(() => {
        onGetUsers({});
    }, [onGetUsers])

    const handleAdd = () => {
        let modal = {
            isOpenModal: true,
            title: "Thêm mới người dùng"
        }
        onOpenModalAdd(modal)
    }

    const handleSearch = (e) => {
        let data = { key_word: e.target.value };
        onGetUsers(data);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Users</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h6 className="page-title">Người dùng</h6>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Danh mục</li>
                                    <li className="breadcrumb-item active">Người dùng</li>
                                </ol>
                            </Col>
                        </Row>
                    </div>
                    {/* Table here */}
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <CardTitle className="h4">Danh sách người dùng</CardTitle>
                                            <p className="card-title-desc">
                                                Quản lý tập người dùng của Qi
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={8}>
                                            <div className='mb-2'>
                                                <ButtonAnt type="primary" className='me-1' onClick={() => handleAdd()}>+ Thêm mới</ButtonAnt>
                                                <Add></Add>
                                                {/* <ButtonAnt className='me-1'><i className='fas fa-filter text-primary'></i></ButtonAnt> */}
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className='float-end mb-2'>
                                                <InputAnt onChange={(e) => handleSearch(e)} placeholder="search..." style={{
                                                    width: 200,
                                                }} />
                                            </div>

                                        </Col>
                                    </Row>
                                    <UserTable></UserTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )
}

Users.propTypes = {
    t: PropTypes.any,
    users: PropTypes.array,
    onGetUsers: PropTypes.func,
    onOpenModalAdd: PropTypes.func,
}

const mapStateToProps = ({ user }) => ({
    users: user.users,
})

const mapDispatchToProps = dispatch => ({
    onGetUsers: (param_filter) => dispatch(getUsers(param_filter)),
    onOpenModalAdd: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_ADD, payload: modal }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Users))
