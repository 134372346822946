import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import deviceSaga from "./device/saga"
import subscriptionSaga from "./subscriptionDevice/saga"
import cutSignalSaga from "./cutSignallist/saga"
import userSaga from "./user/saga"

// import deviceSaga from "./v1/device/saga"
// import subscriptionSaga from "./v1/subscriptionDevice/saga"
// import cutSignalSaga from "./v1/cutSignallist/saga"
// import userSaga from "./v1/user/saga"

import helperSaga from "./helper/saga"
import modalSaga from "./modal/saga"


export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(deviceSaga),
    fork(subscriptionSaga),
    fork(cutSignalSaga),
    fork(userSaga),
    fork(helperSaga),
    fork(modalSaga),
  ])
}
