import React, { useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, Switch, Input as InputAnt } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined, ApiOutlined } from '@ant-design/icons';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalAdd,
    addNewUser
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';

const Add = (props) => {
    const { modalAdd, onCloseModalAdd, onAddNewUser } = props;
    const [fields, setFields] = useState({});
    const [myError, setMyErrors] = useState({});

    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleOk = () => {
        onCloseModalAdd();
    };
    const handleCancel = () => {
        onCloseModalAdd();
    };

    const onSubmit = data => {
        if (validateInput()) {
            let newData = { ...data, ...fields }
            onAddNewUser(newData);
        }

    };

    const validateInput = () => {
        let isValid = true;
        let errs = myError;
        if (fields.password) {
            if (!fields.confirm_password) {
                errs.confirm_password = {
                    type: 'required',
                    message: 'Vui lòng nhập xác nhận mật khẩu'
                }
                isValid = false;
            }
            else {
                if (fields.password !== fields.confirm_password) {
                    errs.confirm_password = {
                        type: 'same',
                        message: 'Mật khẩu xác nhận không khớp'
                    }
                    isValid = false;
                }
            }

        }
        // if (!fields.r_name) {
        //     errs.r_name = {
        //         type: 'required',
        //         message: 'Vai trò không được phép để trống'
        //     }
        //     isValid = false;
        // }
        setMyErrors(errs);
        return isValid;
    }

    const onChange = name => (value) => {
        console.log(`selected ${value}`);
        fields[name] = value;
        setFields(fields);
    };

    const handleChangeInput = e => {
        let field = fields;
        field[e.target.name] = e.target.value
        setFields(field);
    }

    return (
        <>
            <Modal isOpen={modalAdd.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
                    <ModalBody>

                        <Container>
                            <Row className='mb-3'>

                                <Col md={4}>
                                    <Label>Tên tài khoản <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <input
                                            aria-invalid={errors.username ? "true" : "false"}
                                            {...register('username', {
                                                required: true,
                                            })}
                                            className="form-control"
                                            placeholder='Tên tài khoản...'
                                        />
                                        {errors.username && errors.username.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên tài khoản không được để trống</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Email<span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <input
                                        aria-invalid={errors.email ? "true" : "false"}
                                        {...register('email', {
                                            required: true,
                                            pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
                                        })}
                                        className="form-control"
                                        placeholder='Vui lòng nhập email...'
                                    />
                                    {errors.email && errors.email.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Email không được để trống</span>
                                    )}
                                    {errors.email && errors.email.type === "pattern" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Email không đúng định dạng</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Tên <span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <input
                                        aria-invalid={errors.name ? "true" : "false"}
                                        {...register("name", {
                                            required: true,
                                        })}
                                        className="form-control"
                                        placeholder='Tên...' />
                                    {errors.name && errors.name.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên không được để trống</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <Label>Password <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <InputAnt.Password
                                            name="password"
                                            onChange={handleChangeInput}
                                            placeholder="Password..."
                                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <Label>Xác nhận mật khẩu <span className='text-danger'>*</span></Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <InputAnt.Password
                                            name="confirm_password"
                                            onChange={handleChangeInput}
                                            placeholder="Xác nhận password..."
                                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                        {myError.confirm_password && myError.confirm_password.type === "same" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.confirm_password.message}</span>
                                        )}
                                        {myError.confirm_password && myError.confirm_password.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.confirm_password.message}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/* <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Là admin</label>
                                </Col>
                                <Col md={8}>

                                    <Switch defaultChecked onChange={onChange('isAdmin')} />
                                </Col>
                            </Row> */}
                            {/* <Row className='mb-3'>
                                <Col md={4}>
                                    <label>Vai trò <span className='text-danger'>*</span></label>
                                </Col>
                                <Col md={8}>
                                    <SelectAnt
                                        showSearch
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        style={{
                                            width: '100%',
                                        }}
                                        options={[
                                            {
                                                value: 'Admin',
                                                label: 'Admin',
                                            },
                                            {
                                                value: 'System',
                                                label: 'System',
                                            },
                                            {
                                                value: 'Operator',
                                                label: 'Operator',
                                            },
                                        ]}
                                    />
                                    {myError.r_name && myError.r_name.type === "required" && (
                                        <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Vai trò không được để trống</span>
                                    )}
                                </Col>
                            </Row> */}
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <ButtonAnt htmlType="submit" type="primary">
                            Thêm mới
                        </ButtonAnt>{' '}
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};
Add.propTypes = {
    t: PropTypes.any,
    modalAdd: PropTypes.object,
    onCloseModalAdd: PropTypes.func,
    onAddNewUser: PropTypes.func
}

const mapStateToProps = ({ user, helper }) => ({
    modalAdd: helper.modalAdd
})

const mapDispatchToProps = dispatch => ({
    onCloseModalAdd: () => dispatch(closeModalAdd()),
    onAddNewUser: (message) => dispatch(addNewUser(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add);