/* DEVICES */
export const GET_DEVICES = "GET_DEVICES"
export const GET_DEVICES_BY_FILTER = "GET_DEVICES_BY_FILTER"

export const GET_DEVICES_SUCCESS = "GET_DEVICES_SUCCESS"
export const GET_DEVICES_FAIL = "GET_DEVICES_FAIL"

export const ADD_NEW_DEVICE = "ADD_NEW_DEVICE"
export const ADD_DEVICE_SUCCESS = "ADD_DEVICE_SUCCESS"
export const ADD_DEVICE_FAIL = "ADD_DEVICE_FAIL"

export const UPDATE_DEVICE = "UPDATE_DEVICE"
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS"
export const UPDATE_DEVICE_FAIL = "UPDATE_DEVICE_FAIL"

export const DELETE_DEVICE = "DELETE_DEVICE"
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS"
export const DELETE_DEVICE_FAIL = "DELETE_DEVICE_FAIL"

export const SET_DEVICE_FILTER = "SET_DEVICE_FILTER"
