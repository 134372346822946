export const device_types = [
    { value: '', label: '== Vui lòng chọn loại thiết bị ==' },
    { value: 'adsl', label: 'ADSL' },
    { value: 'aon', label: 'AON' },
    { value: 'gpon', label: 'GPON' },
];

export const manufacturers = [
    { value: '', label: '== Vui lòng chọn hãng sản xuất ==' },
    { value: 'mikrotik', label: 'Mikrotik' },
    { value: 'cisco', label: 'Cisco' },
    { value: 'olt', label: 'OLT' },
    { value: 'hp', label: 'HP' },
    { value: 'alcatel', label: 'Alcatel' },
    { value: 'zyxel', label: 'Zyxel' },
    { value: 'voltek', label: 'Voltek' },
];