import React, { useEffect, useState } from 'react';
import { Table, Modal, Spin } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_EDIT } from 'store/helper/actionTypes';
import {
    deleteDevice
} from "../../store/actions"
import { checkStatusDevice } from 'helpers/backend_api/devices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "lodash";

const DeviceTable = (props) => {

    const columns = [
        {
            title: 'Tên thiết bị',
            width: 200,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Loại thiết bị',
            width: 100,
            dataIndex: 'device_type',
            key: 'device_type',
        },
        {
            title: 'Hãng sản xuất',
            width: 100,
            dataIndex: 'manufacturer',
            key: 'manufacturer',
        },
        {
            title: 'Model',
            width: 100,
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Serial',
            width: 100,
            dataIndex: 'serial',
            key: 'serial',
        },
        {
            title: 'IP',
            width: 150,
            dataIndex: 'ip',
            key: 'ip',
        },
        {
            title: 'Username',
            width: 100,
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Port',
            width: 100,
            dataIndex: 'port_number',
            key: 'port_number',
        },
        {
            title: 'Địa chỉ mac',
            width: 100,
            dataIndex: 'mac_address',
            key: 'mac_address',
        },
        {
            title: 'Ghi chú',
            width: 100,
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Thao tác',
            key: 'operation',
            fixed: 'right',
            width: 150,
            render: (text, record, index) => <>
                <button type="button" onClick={() => handleCheckStatusDevice(record)} className="btn btn-outline-warning btn-sm waves-effect waves-light me-1"><i className="fas fa-plug"></i></button>
                <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
            </>,
        },
    ]

    const { devices, onOpenModalEdit, onDeleteDevice, deleteSuccess } = props;
    const [dataRow, setDataRow] = useState({});
    const [loading, setLoading] = useState(false);
    const [devicesList, setDevices] = useState([]);

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Cập nhật thiết bị"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }

    const handleCheckStatusDevice = (row) => {
        setLoading(true);
        row["show_pass"] = true;
        row["port"] = row.port_number;
        checkStatusDevice(row).then(res => {
            if (res.result === true) {
                toast.success(`Device name '${row.name}' is ${res.message}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
            else {
                toast.error(`Device name '${row.name}' is ${res.message}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
        });
    }


    useEffect(() => {
        let data = [];
        devices?.map(device => {
            data.push({
                key: device._id,
                id: device._id,
                device_type: device.device_type,
                manufacturer: device.manufacturer,
                name: device.name,
                model: device.model,
                serial: device.serial,
                ip: device.ip,
                username: device.username,
                port_number: device.port_number,
                mac_address: device.mac_address,
                note: device.note,
            })
        });
        setDevices(data)
    }, [devices])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá thiết bị "${row.name}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                // console.log(message);
                onDeleteDevice(row);
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    useEffect(() => {
        if (!_.isEmpty(deleteSuccess)) {
            toast.success(deleteSuccess.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [deleteSuccess])

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Edit dataRow={dataRow}></Edit>
            <Spin tip="Đang kiểm tra kết nối. Vui lòng đợi..." spinning={loading}>

                <Table
                    columns={columns}
                    dataSource={devicesList}
                    scroll={{
                        x: 1500,
                        y: 500,
                    }}
                />
            </Spin>

        </>

    );

};

DeviceTable.propTypes = {
    t: PropTypes.any,
    devices: PropTypes.array,
    onOpenModalEdit: PropTypes.func,
    onDeleteDevice: PropTypes.func,
    deleteSuccess: PropTypes.object
}

const mapStateToProps = ({ device }) => ({
    devices: device.devices,
    deleteSuccess: device.success
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onDeleteDevice: (message) => dispatch(deleteDevice(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTable); 