import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    CardHeader
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react";

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png";

//i18n
import { withTranslation } from "react-i18next"
import DeviceTable from './DeviceTable';
import { Input as InputAnt, Button as ButtonAnt } from 'antd';


import {
    getDevices,
    getDevicesByFilter
} from "../../store/actions"
import { connect } from "react-redux"
import { get } from 'lodash';
import { CHANGE_OPEN_MODAL_ADD, CHANGE_OPEN_MODAL_FILTER } from 'store/helper/actionTypes';
import Add from "./Add"
import Filter from "./Filter"


const Devices = props => {
    const [menu, setMenu] = useState(false)
    const { onGetDevices, onOpenModalAdd, filter, onOpenModalFilter, onGetDevicesByFilter } = props
    const toggle = () => {
        setMenu(!menu)
    }

    useEffect(() => {
        onGetDevices();
    }, [onGetDevices])

    const handleAdd = () => {
        let modal = {
            isOpenModal: true,
            title: "Thêm mới thiết bị"
        }
        onOpenModalAdd(modal)
    }

    const handleFilter = () => {
        let modal = {
            isOpenModal: true,
            title: "Lọc danh sách thiết bị"
        }
        onOpenModalFilter(modal)
    }

    const handleSearch = (e) => {
        let param_filter = filter
        param_filter["key_word"] = e.target.value
        onGetDevicesByFilter(param_filter);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Thiết bị</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h6 className="page-title">Thiết bị</h6>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Main</li>
                                    <li className="breadcrumb-item active">Thiết bị</li>
                                </ol>
                            </Col>
                        </Row>
                    </div>
                    {/* Table here */}
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <CardTitle className="h4">Danh sách thiết bị</CardTitle>
                                            <p className="card-title-desc">
                                                Quản lý tập thiết bị của Qi
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={8}>
                                            <div className='mb-2'>
                                                <ButtonAnt type="primary" className='me-1' onClick={() => handleAdd()}>+ Thêm mới</ButtonAnt>
                                                <Add></Add>
                                                <ButtonAnt className='me-1' onClick={() => handleFilter()}><i className='fas fa-filter text-primary'></i></ButtonAnt>
                                                <Filter></Filter>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className='float-end mb-2'>
                                                <InputAnt onChange={(e) => handleSearch(e)} placeholder="search..." style={{
                                                    width: 200,
                                                }} />
                                            </div>

                                        </Col>
                                    </Row>
                                    <DeviceTable></DeviceTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )
}

Devices.propTypes = {
    t: PropTypes.any,
    devices: PropTypes.array,
    onGetDevices: PropTypes.func,
    onOpenModalAdd: PropTypes.func,
    onOpenModalFilter: PropTypes.func,
    filter: PropTypes.object,
    onGetDevicesByFilter: PropTypes.func
}

const mapStateToProps = ({ device, filter }) => ({
    devices: device.devices,
    filter: device.filter,
})

const mapDispatchToProps = dispatch => ({
    onGetDevices: () => dispatch(getDevices()),
    onOpenModalAdd: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_ADD, payload: modal }),
    onOpenModalFilter: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_FILTER, payload: modal }),
    onGetDevicesByFilter: (data_merged) => dispatch(getDevicesByFilter(data_merged)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Devices))
