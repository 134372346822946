import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

import device from "./device/reducer"
import subscription from "./subscriptionDevice/reducer"
import cutSignal from "./cutSignallist/reducer"
import user from "./user/reducer"

// helper
import helper from "./helper/reducer";
import modal from "./modal/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  device,
  subscription,
  cutSignal,
  user,
  helper,
  modal
})

export default rootReducer
