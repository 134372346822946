/* CUT_SIGNAL */
export const GET_CUT_SIGNALS = "GET_CUT_SIGNALS"
export const GET_CUT_SIGNALS_BY_FILTER = "GET_CUT_SIGNALS_BY_FILTER"
export const GET_CUT_SIGNALS_SUCCESS = "GET_CUT_SIGNALS_SUCCESS"
export const GET_CUT_SIGNALS_FAIL = "GET_CUT_SIGNALS_FAIL"

export const UPDATE_CUT_SIGNAL = "UPDATE_CUT_SIGNAL"
export const UPDATE_CUT_SIGNAL_SUCCESS = "UPDATE_CUT_SIGNAL_SUCCESS"
export const UPDATE_CUT_SIGNAL_FAIL = "UPDATE_CUT_SIGNAL_FAIL"

export const DELETE_CUT_SIGNAL = "DELETE_CUT_SIGNAL"
export const DELETE_CUT_SIGNAL_SUCCESS = "DELETE_CUT_SIGNAL_SUCCESS"
export const DELETE_CUT_SIGNAL_FAIL = "DELETE_CUT_SIGNAL_FAIL"

export const SET_CUT_SIGNALS_FILTER = "SET_CUT_SIGNALS_FILTER"

