import {
  MODAL_CHANGE,
  MODAL_CHANGE_ACTION
} from "./actionTypes"

export const modalChange = properties => ({
  type: MODAL_CHANGE,
  payload: properties,
})

export const modalChangeAction = properties => ({
  type: MODAL_CHANGE_ACTION,
  payload: properties,
})