import {
  MODAL_CHANGE_ACTION,
} from "./actionTypes"

const INIT_STATE = {
  properties: {
    isOpen: false,
    type: '',
    title: '',
    subTitle: '',
    message: '',
  },
  error: {},
}

const ModalHelper = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MODAL_CHANGE_ACTION:
      return {
        ...state,
        properties: action.payload,
      }
    default:
      return state
  }
}

export default ModalHelper
