import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_DEVICE,
  DELETE_DEVICE,
  GET_DEVICES,
  GET_DEVICES_BY_FILTER,
  UPDATE_DEVICE,
} from "./actionTypes"
import {
  getDevicesSuccess,
  getDevicesFail,
  addDeviceFail,
  addDeviceSuccess,
  updateDeviceSuccess,
  updateDeviceFail,
  deleteDeviceSuccess,
  deleteDeviceFail,
  setDeviceFilter
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getDevices,
  getDevicesByFilter,
  addNewDevice,
  updateDevice,
  deleteDevice
} from "../../helpers/backend_api/devices"

function* fetchDevices() {
  try {
    const response = yield call(getDevices)
    if (response.status === true) {
      yield put(getDevicesSuccess(response.message))
    }
    else {
      yield put(getDevicesFail(error))
    }
  } catch (error) {
    yield put(getDevicesFail(error))
  }
}

function renderFilterUrl(param_filter) {
  let filter = Object.keys(param_filter).map(function (key) {
    return key + '=' + param_filter[key];
  }).join('&');

  filter = '?' + filter;
  return filter;
}

function* fetchDevicesByFilter({ payload: param_filter }) {
  yield put(deleteDeviceSuccess({ message: {} }))
  let filter_url = renderFilterUrl(param_filter);
  try {
    const response = yield call(getDevicesByFilter, filter_url)
    if (response.status === true) {
      yield put(getDevicesSuccess(response.message))
      yield put(setDeviceFilter(param_filter))
    }
    else {
      yield put(getDevicesFail(error))
    }
  } catch (error) {
    yield put(getDevicesFail(error))
  }
}

function* onAddNewDevice({ payload: device }) {
  try {
    const response = yield call(addNewDevice, device);
    if (response.status === true) {
      const device = yield call(getDevices)
      if (device.status === true) {
        yield put(getDevicesSuccess(device.message))
        yield put(closeModalAdd())
      }
      else {
        yield put(getDevicesFail(error))
      }
    }
  } catch (error) {
    yield put(addDeviceFail(error))
  }
}

function* onUpdateDevice({ payload: device }) {
  try {
    const response = yield call(updateDevice, device);
    if (response.status === true) {
      const device = yield call(getDevices)
      if (device.status === true) {
        yield put(getDevicesSuccess(device.message))
        yield put(closeModalEdit())
      }
      else {
        yield put(getDevicesFail(error))
      }
    }
  } catch (error) {
    yield put(updateDeviceFail(error))
  }
}

function* onDeleteDevice({ payload: device }) {
  try {
    const deleteResponse = yield call(deleteDevice, device);
    if (deleteResponse.status === true) {
      const device = yield call(getDevices)
      if (device.status === true) {
        yield put(deleteDeviceSuccess({ message: deleteResponse, device: device.message }))
      }
      else {
        yield put(deleteDeviceFail(error))
      }
    }
  } catch (error) {
    yield put(deleteDeviceFail(error))
  }
}

function* deviceSaga() {
  yield takeEvery(GET_DEVICES, fetchDevices)
  yield takeLatest(GET_DEVICES_BY_FILTER, fetchDevicesByFilter)
  yield takeEvery(ADD_NEW_DEVICE, onAddNewDevice)
  yield takeEvery(UPDATE_DEVICE, onUpdateDevice)
  yield takeEvery(DELETE_DEVICE, onDeleteDevice)
}

export default deviceSaga
