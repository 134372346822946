import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"
// Devices 
import Devices from "../pages/Devices/index"
import SubscriptionDevice from "../pages/SubscriptionDevice/index"
// Users 
import Users from "../pages/Users/index"
import CutSignalImport from "pages/CutSignalImport"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

const userRoutes = [
  // Categories
  { path: "/devices", component: Devices },
  { path: "/subscription-port", component: SubscriptionDevice },
  { path: "/cut-signals", component: CutSignalImport },
  { path: "/users", component: Users },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/devices" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes }
