import React, { useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, Input as InputAnt, InputNumber, Spin } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined, ApiOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalAdd,
    addNewDevice
} from "../../store/actions"
import { checkStatusDevice } from 'helpers/backend_api/devices';
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';
import { device_types, manufacturers } from '../../common/data/device';

const Add = (props) => {
    const { modalAdd, onCloseModalAdd, onAddNewDevice } = props;

    const [fields, setFields] = useState({});
    const [myError, setMyErrors] = useState({});

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [isConnect, setIsShowConnect] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        onCloseModalAdd();
    };

    const handleConnect = (data_merged) => {
        // let my_data = {
        //     'ip': '192.168.183.1',
        //     'username': 'sbdroot',
        //     'password': 'rootSBDS@2020',
        //     'port': 24
        // }
        setLoading(true);
        checkStatusDevice(data_merged).then(res => {
            if (res.result === true) {
                toast.success(res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setIsShowConnect(true)
                setLoading(false);
            }
            else {
                toast.error(res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setIsShowConnect(false)
                setLoading(false);
            }
        });
    }

    const onSubmit = data => {
        if (handleValidate()) {
            let data_merged = { ...data, ...fields };
            if (!isConnect) {
                handleConnect(data_merged)
            } else {
                // Post data to server
                onAddNewDevice(data_merged);
                setIsShowConnect(false)
            }
        }

    };

    const handleValidate = () => {

        let currentFields = fields;
        let isValid = true;
        let errs = {};
        if (!currentFields.device_type) {
            errs.device_type = {
                type: 'required',
                message: 'Loại thiết bị không được phép để trống'
            }
            isValid = false;
        }

        if (!currentFields.manufacturer) {
            errs.manufacturer = {
                type: 'required',
                message: 'Hãng sản xuất không được phép để trống'
            }
            isValid = false;
        }

        if (!currentFields.password) {
            errs.password = {
                type: 'required',
                message: 'Mật khẩu không được phép để trống'
            }
            isValid = false;
        }

        if (!currentFields.port) {
            errs.port = {
                type: 'required',
                message: 'Port không được phép để trống'
            }
            isValid = false;
        }
        setMyErrors(errs);
        return isValid;
    }

    const handleChange = name => value => {
        let field = fields;
        field[name] = value
        setFields(field);
    }

    const handleChangeInput = e => {
        let field = fields;
        field[e.target.name] = e.target.value
        setFields(field);
    }

    return (
        <>
            <Modal isOpen={modalAdd.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Spin tip="Đang kiểm tra kết nối. Vui lòng đợi..." spinning={loading}>

                        <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
                        <ModalBody>

                            <Container>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Loại thiết bị <span className='text-danger'>*</span></Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <SelectAnt
                                                onChange={handleChange('device_type')}
                                                defaultValue=""
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={device_types}
                                            />
                                            {myError.device_type && myError.device_type.type === "required" && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Loại thiết bị không được để trống</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Hãng sản xuất <span className='text-danger'>*</span></Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <SelectAnt
                                                onChange={handleChange('manufacturer')}
                                                defaultValue=""
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={manufacturers}
                                            />
                                            {myError.manufacturer && myError.manufacturer.type === "required" && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Hãng sản xuất không được để trống</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Tên thiết bị <span className='text-danger'>*</span></Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <input
                                                aria-invalid={errors.name ? "true" : "false"}
                                                {...register('name', {
                                                    required: true,
                                                })}
                                                className="form-control"
                                                placeholder='Tên thiết bị...'
                                            />
                                            {errors.name && errors.name.type === "required" && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Tên thiết bị không được để trống</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Model </Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <input
                                                aria-invalid={errors.model ? "true" : "false"}
                                                {...register('model')}
                                                className="form-control"
                                                placeholder='Model...'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Serial </Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <input
                                                aria-invalid={errors.serial ? "true" : "false"}
                                                {...register('serial')}
                                                className="form-control"
                                                placeholder='serial...'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Địa chỉ mac </Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <input
                                                aria-invalid={errors.mac_address ? "true" : "false"}
                                                {...register('mac_address')}
                                                className="form-control"
                                                placeholder='Địa chỉ mac...'
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>IP <span className='text-danger'>*</span></Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <input
                                                aria-invalid={errors.serial ? "true" : "false"}
                                                {...register('ip', {
                                                    required: true,
                                                })}
                                                className="form-control"
                                                placeholder='IP...'
                                            />
                                            {errors.ip && errors.ip.type === "required" && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>IP không được để trống</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Username <span className='text-danger'>*</span></Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <input
                                                aria-invalid={errors.serial ? "true" : "false"}
                                                {...register('username', {
                                                    required: true,
                                                })}
                                                className="form-control"
                                                placeholder='Username...'
                                            />
                                            {errors.username && errors.username.type === "required" && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Username không được để trống</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Password <span className='text-danger'>*</span></Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <InputAnt.Password
                                                name="password"
                                                onChange={handleChangeInput}
                                                placeholder="Password..."
                                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                            />
                                            {myError.password && myError.password.type === "required" && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Password không được để trống</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Port <span className='text-danger'>*</span></Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <InputNumber onChange={handleChange('port')} min={1} max={999999} placeholder="ex: 22" />
                                            <br />
                                            {myError.port && myError.port.type === "required" && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Port không được để trống</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <label>Mô tả</label>
                                    </Col>
                                    <Col md={8}>
                                        <textarea
                                            aria-invalid={errors.description ? "true" : "false"}
                                            {...register('description')}
                                            className="form-control"
                                            placeholder='Mô tả...'
                                        ></textarea>
                                    </Col>
                                </Row>
                            </Container>

                        </ModalBody>
                        <ModalFooter>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                            {isConnect && (
                                <ButtonAnt htmlType="submit" type="primary">
                                    + Thêm mới
                                </ButtonAnt>
                            )}

                            {!isConnect && (
                                <ButtonAnt icon={<ApiOutlined />} htmlType="submit" className='text-white' style={{ backgroundColor: "#FFC93C" }}>
                                    Kiểm tra kết nối
                                </ButtonAnt>
                            )}
                            <ButtonAnt onClick={handleCancel}>
                                Đóng
                            </ButtonAnt>
                        </ModalFooter>
                    </Spin>

                </Form>
            </Modal>
        </>
    );
};
Add.propTypes = {
    t: PropTypes.any,
    modalAdd: PropTypes.object,
    onCloseModalAdd: PropTypes.func,
    onAddNewDevice: PropTypes.func
}

const mapStateToProps = ({ helper }) => ({
    modalAdd: helper.modalAdd
})

const mapDispatchToProps = dispatch => ({
    onCloseModalAdd: () => dispatch(closeModalAdd()),
    onAddNewDevice: (data) => dispatch(addNewDevice(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add);