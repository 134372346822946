/* SUBSCRIPTIONS */
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS"
export const GET_SUBSCRIPTIONS_BY_FILTER = "GET_SUBSCRIPTIONS_BY_FILTER"
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS"
export const GET_SUBSCRIPTIONS_FAIL = "GET_SUBSCRIPTIONS_FAIL"

export const ADD_NEW_SUBSCRIPTION = "ADD_NEW_SUBSCRIPTION"
export const ADD_SUBSCRIPTION_SUCCESS = "ADD_SUBSCRIPTION_SUCCESS"
export const ADD_SUBSCRIPTION_FAIL = "ADD_SUBSCRIPTION_FAIL"

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION"
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS"
export const UPDATE_SUBSCRIPTION_FAIL = "UPDATE_SUBSCRIPTION_FAIL"

export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION"
export const DELETE_SUBSCRIPTION_SUCCESS = "DELETE_SUBSCRIPTION_SUCCESS"
export const DELETE_SUBSCRIPTION_FAIL = "DELETE_SUBSCRIPTION_FAIL"

export const GET_CONSTRAINTS = "GET_CONSTRAINTS"
export const GET_CONSTRAINTS_SUCCESS = "GET_CONSTRAINTS_SUCCESS"
export const GET_CONSTRAINTS_FAIL = "GET_CONSTRAINTS_FAIL"

export const SET_SUBSCRIPTION_FILTER = "SET_SUBSCRIPTION_FILTER"

