import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, Input as InputAnt, InputNumber, Spin } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined, ApiOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalAdd,
    addNewSubscription,
    getConstraints
} from "../../store/actions"
import { checkCustomerStatus } from 'helpers/backend_api/subscriptions';
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label
} from "reactstrap"
import { useForm } from 'react-hook-form';

const Add = (props) => {
    const { modalAdd, onCloseModalAdd, onAddNewSubscription, onGetConstraints, constraints } = props;

    const [fields, setFields] = useState({});
    const [myError, setMyErrors] = useState({});

    const [devices, setDevices] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [isConnect, setIsShowConnect] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        onCloseModalAdd();
    };

    useEffect(() => {
        onGetConstraints();
    }, [onGetConstraints])

    useEffect(() => {
        if (!_.isEmpty(constraints)) {
            let deviceArr = [{ value: "", label: "== Vui lòng chọn thiết bị ==" }];
            constraints.devices.map(device => {
                deviceArr.push({ value: device._id, label: device.name })
            })
            setDevices(deviceArr);

            let statusArr = [{ value: "", label: "== Vui lòng chọn trạng thái ==" }];
            constraints.status.map(state => {
                statusArr.push({ value: state._id, label: state.name })
            })
            setStatuses(statusArr);
        }
    }, [constraints])

    const handleConnect = (data_merged) => {
        // let my_data = {
        //     'ip': '192.168.183.1',
        //     'username': 'sbdroot',
        //     'password': 'rootSBDS@2020',
        //     'port': 24
        // }
        setLoading(true);
        checkCustomerStatus(data_merged).then(res => {
            if (res.result === true) {
                toast.success(res.port_status, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setIsShowConnect(true)
                setLoading(false);
            }
            else {
                toast.error(res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setIsShowConnect(false)
                setLoading(false);
            }
        });
    }

    const onSubmit = data => {
        if (handleValidate()) {
            let data_merged = { ...data, ...fields };
            if (!isConnect) {
                handleConnect(data_merged)
            } else {
                // Post data to server
                onAddNewSubscription(data_merged);
            }
        }

    };

    const handleValidate = () => {

        let currentFields = fields;
        let isValid = true;
        let errs = {};
        if (!currentFields.device) {
            errs.device = {
                type: 'required',
                message: 'Vui lòng chọn thiết bị'
            }
            isValid = false;
        }

        if (!currentFields.status) {
            errs.status = {
                type: 'required',
                message: 'Vui lòng chọn trạng thái'
            }
            isValid = false;
        }

        setMyErrors(errs);
        return isValid;
    }

    const handleChange = name => value => {
        let field = fields;
        field[name] = value
        setFields(field);
    }

    return (
        <>
            <Modal isOpen={modalAdd.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Spin tip="Đang kiểm tra kết nối. Vui lòng đợi..." spinning={loading}>

                        <ModalHeader toggle={handleCancel}>{modalAdd.title}</ModalHeader>
                        <ModalBody>
                            <Container>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Thiết bị <span className='text-danger'>*</span></Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <SelectAnt
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                onChange={handleChange('device')}
                                                defaultValue=""
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={devices}
                                            />
                                            {myError.device && myError.device.type === "required" && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.device.message}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Trạng thái của người dùng <span className='text-danger'>*</span></Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <SelectAnt
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                onChange={handleChange('status')}
                                                defaultValue=""
                                                style={{
                                                    width: '100%',
                                                }}
                                                options={statuses}
                                            />
                                            {myError.status && myError.status.type === "required" && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.status.message}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Mã dịch vụ <span className='text-danger'>*</span></Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <input
                                                aria-invalid={errors.sub_code ? "true" : "false"}
                                                {...register('sub_code', {
                                                    required: true,
                                                })}
                                                className="form-control"
                                                placeholder='Mã dịch vụ...'
                                            />
                                            {errors.sub_code && errors.sub_code.type === "required" && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{errors.sub_code.message}</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <Label>Port <span className='text-danger'>*</span></Label>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <input
                                                aria-invalid={errors.port ? "true" : "false"}
                                                {...register('port', {
                                                    required: true,
                                                })}
                                                className="form-control"
                                                placeholder='Port khách hàng...'
                                            />
                                            {myError.port && myError.port.type === "required" && (
                                                <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>Port không được để trống</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={4}>
                                        <label>Mô tả</label>
                                    </Col>
                                    <Col md={8}>
                                        <textarea
                                            aria-invalid={errors.note ? "true" : "false"}
                                            {...register('note')}
                                            className="form-control"
                                            placeholder='Mô tả...'
                                        ></textarea>
                                    </Col>
                                </Row>
                            </Container>

                        </ModalBody>
                        <ModalFooter>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                            {isConnect && (
                                <ButtonAnt htmlType="submit" type="primary">
                                    + Thêm mới
                                </ButtonAnt>
                            )}

                            {!isConnect && (
                                <ButtonAnt icon={<ApiOutlined />} htmlType="submit" className='text-white' style={{ backgroundColor: "#FFC93C" }}>
                                    Kiểm tra kết nối
                                </ButtonAnt>
                            )}
                            <ButtonAnt onClick={handleCancel}>
                                Đóng
                            </ButtonAnt>
                        </ModalFooter>
                    </Spin>

                </Form>
            </Modal>
        </>
    );
};
Add.propTypes = {
    t: PropTypes.any,
    modalAdd: PropTypes.object,
    onCloseModalAdd: PropTypes.func,
    onAddNewSubscription: PropTypes.func,
    onGetConstraints: PropTypes.func,
    constraints: PropTypes.object

}

const mapStateToProps = ({ helper, subscription }) => ({
    modalAdd: helper.modalAdd,
    constraints: subscription.constraints
})

const mapDispatchToProps = dispatch => ({
    onCloseModalAdd: () => dispatch(closeModalAdd()),
    onAddNewSubscription: (data) => dispatch(addNewSubscription(data)),
    onGetConstraints: () => dispatch(getConstraints())
})

export default connect(mapStateToProps, mapDispatchToProps)(Add);