import PropTypes, { array } from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    CardHeader
} from "reactstrap"
import { Link } from "react-router-dom"

// Custom Scrollbar
import SimpleBar from "simplebar-react";

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png";

//i18n
import { withTranslation } from "react-i18next"
import SubscriptionDeviceTable from './SubscriptionDeviceTable';
import { Input as InputAnt, Button as ButtonAnt, Tag } from 'antd';


import {
    getSubscriptions,
    getConstraints,
    getSubscriptionsByFilter
} from "../../store/actions"
import { connect } from "react-redux"
import { get } from 'lodash';
import { CHANGE_OPEN_MODAL_ADD, CHANGE_OPEN_MODAL_FILTER, CHANGE_OPEN_MODAL_UPLOAD } from 'store/helper/actionTypes';
import Add from "./Add"
import Filter from './Filter';
import _ from "lodash";
import Upload from './Upload';

const SubscriptionDevice = props => {
    const [menu, setMenu] = useState(false)
    const [filterItem, setFilterItem] = useState([]);
    const {
        onGetSubscriptions,
        onGetConstraints,
        onOpenModalAdd,
        onOpenModalFilter,
        onOpenModalUpload,
        filter,
        constraints,
        onGetSubscriptionsByFilter
    } = props
    const toggle = () => {
        setMenu(!menu)
    }

    useEffect(() => {
        let items = [];
        setFilterItem([]);
        if (!_.isEmpty(filter)) {
            if (!_.isEmpty(filter.device)) {
                let device = constraints.devices.filter(device => device._id === filter.device);
                items.push(device[0].name)
            }
            if (!_.isEmpty(filter.status)) {
                let status = constraints.status.filter(status => status._id === filter.status);
                items.push(status[0].name)
            }
            setFilterItem(items);
        }
    }, [filter])

    useEffect(() => {
        onGetConstraints();
    }, [onGetConstraints])

    useEffect(() => {
        onGetSubscriptions();
    }, [onGetSubscriptions])

    const handleAdd = () => {
        let modal = {
            isOpenModal: true,
            title: "Thêm mới cổng dịch vụ người dùng"
        }
        onOpenModalAdd(modal)
    }

    const handleFilter = () => {
        let modal = {
            isOpenModal: true,
            title: "Lọc danh sách cổng dịch vụ người dùng"
        }
        onOpenModalFilter(modal)
    }
    const handleReload = () => {
        onGetSubscriptions();
    }

    const handleUpload = () => {
        let modal = {
            isOpenModal: true,
            title: "Tải lên danh sách cổng dịch vụ khách hàng"
        }
        onOpenModalUpload(modal)
    }

    const handleSearch = (e) => {
        if (!_.isEmpty(e.target.value)) {
            let data = { key_word: e.target.value };
            onGetSubscriptionsByFilter(data);
        } else {
            onGetSubscriptions();
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Cổng thuê bao</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h6 className="page-title">Cổng thuê bao</h6>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Main</li>
                                    <li className="breadcrumb-item active">Cổng thuê bao</li>
                                </ol>
                            </Col>
                        </Row>
                    </div>
                    {/* Table here */}
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <CardTitle className="h4">Danh sách cổng thuê bao</CardTitle>
                                            <p className="card-title-desc">
                                                Quản lý tập cổng thuê bao dành cho khách hàng của Qi
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={8}>

                                            <div className='mb-2'>
                                                <ButtonAnt type="primary" className='me-1' onClick={() => handleAdd()}>+ Thêm mới</ButtonAnt>
                                                <Add></Add>
                                                <ButtonAnt className='me-1' onClick={() => handleFilter()}><i className='fas fa-filter text-primary'></i></ButtonAnt>
                                                <Filter></Filter>

                                                <Button color="success" className='ms-1' onClick={() => handleUpload()}>
                                                    <i className="fas fa-cloud-upload-alt"></i> Tải lên
                                                </Button>
                                                <Upload></Upload>
                                            </div>
                                            {filterItem && (
                                                filterItem.map((item, i) => (
                                                    <Tag key={i} color="processing" size="middle">{item}</Tag>
                                                ))
                                            )}
                                            {!_.isEmpty(filterItem) && (
                                                <ButtonAnt onClick={() => handleReload()}>
                                                    <i className='text-success fas fa-sync'></i>
                                                </ButtonAnt>
                                            )}
                                        </Col>
                                        <Col md={4}>
                                            <div className='float-end mb-2'>
                                                <InputAnt onChange={(e) => handleSearch(e)} placeholder="search..." style={{
                                                    width: 200,
                                                }} />
                                            </div>

                                        </Col>
                                    </Row>
                                    <SubscriptionDeviceTable></SubscriptionDeviceTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )
}

SubscriptionDevice.propTypes = {
    t: PropTypes.any,
    subscriptions: PropTypes.array,
    onGetSubscriptions: PropTypes.func,
    onOpenModalAdd: PropTypes.func,
    onOpenModalFilter: PropTypes.func,
    onGetConstraints: PropTypes.func,
    constraints: PropTypes.object,
    filter: PropTypes.object,
    onGetSubscriptionsByFilter: PropTypes.func
}

const mapStateToProps = ({ subscription }) => ({
    subscriptions: subscription.subscriptions,
    constraints: subscription.constraints,
    filter: subscription.filter,
})

const mapDispatchToProps = dispatch => ({
    onGetSubscriptions: () => dispatch(getSubscriptions()),
    onOpenModalAdd: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_ADD, payload: modal }),
    onOpenModalFilter: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_FILTER, payload: modal }),
    onOpenModalUpload: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_UPLOAD, payload: modal }),
    onGetConstraints: () => dispatch(getConstraints()),
    onGetSubscriptionsByFilter: (keyword) => dispatch(getSubscriptionsByFilter(keyword)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubscriptionDevice))
