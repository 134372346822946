import React, { useEffect, useState } from 'react';
import { Table, Modal, Spin } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CHANGE_OPEN_MODAL_EDIT } from 'store/helper/actionTypes';
import {
    getCutSignals
} from "../../store/actions"
import { checkCustomerStatus, cutSignalSubscription, openSignalSubscription } from 'helpers/backend_api/subscriptions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "lodash";
import moment from 'moment';
import Edit from './Edit';

const CutSignalTable = (props) => {

    const { subscriptions, filter } = props;

    const columns = [
        {
            title: 'Mã dịch vụ',
            width: 100,
            dataIndex: 'sub_code',
            key: 'sub_code',
            fixed: 'left',
        },
        {
            title: 'Trạng thái',
            width: 100,
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                if (text === 1) {
                    return <span className="text-success">Thành công</span>
                } else if (text === 0) {
                    return <span className="text-danger">Thất bại</span>
                }
            }
        },
        {
            title: 'Mô tả',
            width: 100,
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Thời gian thao tác',
            width: 100,
            dataIndex: 'date_modified',
            key: 'date_modified',
        },
        {
            title: 'Ghi chú',
            width: 100,
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Thao tác',
            key: 'operation',
            fixed: 'right',
            width: 80,
            render: (text, record, index) => <>

                {text === 0 && (
                    <button type="button" onClick={() => handleCutSignal(record)} className="btn btn-outline-dark btn-sm waves-effect waves-light me-1"><i className="fas fa-lock"></i></button>
                )}
                <button type="button" onClick={() => handleCheckStatusCustomer(record)} className="btn btn-outline-warning btn-sm waves-effect waves-light me-1"><i className="fas fa-plug"></i></button>
                <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
            </>,
        },
    ]

    const { onGetSubErrorLog, onOpenModalEdit, cutSignals } = props;
    const [dataRow, setDataRow] = useState({});
    const [loading, setLoading] = useState(false);
    const [cutSignalList, setCutSignalList] = useState([]);


    useEffect(() => {
        let data = [];
        cutSignals?.map(signal => {
            data.push({
                key: signal._id,
                id: signal._id,
                sub_code: signal.sub_code,
                status: signal.status,
                description: signal.description,
                date_modified: moment(signal.date_modified).format('DD/MM/YYYY, h:mm:ss a'),
                note: signal.note
            })
        });
        setCutSignalList(data)
    }, [cutSignals])

    // useEffect(() => {
    //     onGetSubErrorLog();
    // }, [onGetSubErrorLog])

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Ghi chú log"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }

    const convertErrorLogToSubscription = (sub) => {
        let data = {
            key: sub._id,
            id: sub._id,
            device: sub.device._id,
            status: sub.status._id,
            device_name: sub.device.name,
            sub_status_name: sub.status.name,
            sub_code: sub.sub_code,
            port: sub.port_user,
            note: sub.note,
        }
        return data;
    }

    const handleCheckStatusCustomer = (row) => {
        setLoading(true);
        const sub = subscriptions.filter(sub => sub.sub_code === row.sub_code);
        const newSub = convertErrorLogToSubscription(sub[0]);
        checkCustomerStatus(newSub).then(res => {
            if (res.result === true) {
                toast.success(`Port username '${newSub.port}' is ${res.port_status}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
            else {
                toast.error(`Port username '${newSub.port}' is ${res.message}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
        });
    }

    const handleCutSignal = (row) => {
        setLoading(true);
        const sub = subscriptions.filter(sub => sub.sub_code === row.sub_code);
        const newSub = convertErrorLogToSubscription(sub[0]);
        cutSignalSubscription(newSub).then(res => {
            if (res.result === true) {
                onGetSubErrorLog(filter);
                toast.success(res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
            else {
                toast.error(res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
        });
    }


    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá cổng dịch vụ khách hàng "${row.port}" của mã dịch vụ "${row.sub_code}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                console.log(row);
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    // useEffect(() => {
    //     if (!_.isEmpty(deleteSuccess)) {
    //         toast.success(deleteSuccess.message, {
    //             position: "top-center",
    //             autoClose: 5000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "light",
    //         });
    //     }
    // }, [deleteSuccess])

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Spin tip="Đang kiểm tra kết nối. Vui lòng đợi..." spinning={loading}>
                <Edit dataRow={dataRow}></Edit>
                <Table
                    columns={columns}
                    dataSource={cutSignalList}
                    scroll={{
                        x: 1500,
                        y: 500,
                    }}
                />
            </Spin>

        </>

    );
};

CutSignalTable.propTypes = {
    cutSignals: PropTypes.array,
    subscriptions: PropTypes.array,
    onOpenModalEdit: PropTypes.func,
    filter: PropTypes.object
}

const mapStateToProps = ({ cutSignal, subscription }) => ({
    cutSignals: cutSignal.cutSignals,
    subscriptions: subscription.subscriptions,
    filter: cutSignal.filter
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
})

export default connect(mapStateToProps, mapDispatchToProps)(CutSignalTable); 