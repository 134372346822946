import {
  GET_CUT_SIGNALS,
  GET_CUT_SIGNALS_FAIL,
  GET_CUT_SIGNALS_SUCCESS,
  UPDATE_CUT_SIGNAL,
  UPDATE_CUT_SIGNAL_SUCCESS,
  UPDATE_CUT_SIGNAL_FAIL,
  DELETE_CUT_SIGNAL,
  DELETE_CUT_SIGNAL_SUCCESS,
  DELETE_CUT_SIGNAL_FAIL,
  GET_CUT_SIGNALS_BY_FILTER,
  SET_CUT_SIGNALS_FILTER
} from "./actionTypes"

export const getCutSignals = (param_filter) => ({
  type: GET_CUT_SIGNALS,
  payload: param_filter
})

export const getCutSignalsByFilter = param_filter => ({
  type: GET_CUT_SIGNALS_BY_FILTER,
  payload: param_filter
})

export const getCutSignalsSuccess = cut_signals => ({
  type: GET_CUT_SIGNALS_SUCCESS,
  payload: cut_signals,
})

export const getCutSignalsFail = error => ({
  type: GET_CUT_SIGNALS_FAIL,
  payload: error,
})

export const updateCutSignal = cut_signal => ({
  type: UPDATE_CUT_SIGNAL,
  payload: cut_signal,
})

export const updateCutSignalSuccess = cut_signal => ({
  type: UPDATE_CUT_SIGNAL_SUCCESS,
  payload: cut_signal,
})

export const updateCutSignalFail = error => ({
  type: UPDATE_CUT_SIGNAL_FAIL,
  payload: error,
})

export const deleteCutSignal = cut_signal => ({
  type: DELETE_CUT_SIGNAL,
  payload: cut_signal,
})

export const deleteCutSignalSuccess = cut_signal => ({
  type: DELETE_CUT_SIGNAL_SUCCESS,
  payload: cut_signal,
})

export const deleteCutSignalFail = error => ({
  type: DELETE_CUT_SIGNAL_FAIL,
  payload: error,
})

export const setCutSignalFilter = param => ({
  type: SET_CUT_SIGNALS_FILTER,
  payload: param,
})