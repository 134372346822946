import PropTypes, { array } from 'prop-types'
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import CutSignalTable from './CutSignalTable';
import { Input as InputAnt, Button as ButtonAnt, Tag, DatePicker, ConfigProvider } from 'antd';

import {
    getConstraints,
    getSubscriptions,
    getCutSignals
} from "../../store/actions"
import { connect } from "react-redux"
import { CHANGE_OPEN_MODAL_UPLOAD } from 'store/helper/actionTypes';
import _ from "lodash";
import Upload from './Upload';

import viVN from 'antd/lib/date-picker/locale/vi_VN'; // import Vietnamese locale object
import dayjs from 'dayjs';

const CutSignalImport = props => {

    const [daysFilter, setDateFilter] = useState(dayjs(dayjs().format('MM/YYYY'), 'MM/YYYY'));
    const {
        onOpenModalUpload,
        onGetSubErrorLog,
        onGetConstraints,
        onGetSubscriptions,
        filter
    } = props

    useEffect(() => {
        let param_filter = {
            'year': dayjs(daysFilter).format('YYYY'),
            'month': dayjs(daysFilter).format('MM'),
        }
        onGetSubErrorLog(param_filter);
        onGetConstraints();
        onGetSubscriptions();
    }, [onGetSubErrorLog, onGetConstraints, onGetSubscriptions])

    const handleUpload = () => {
        let modal = {
            isOpenModal: true,
            title: "Tải lên danh sách cổng dịch vụ khách hàng"
        }
        onOpenModalUpload(modal)
    }

    const handleSearch = (e) => {
        let param_filter = filter
        param_filter["key_word"] = e.target.value
        // console.log(param_filter)
        onGetSubErrorLog(param_filter);
    }
    const handleFilter = () => {
        let param_filter = {
            'year': dayjs(daysFilter).format('YYYY'),
            'month': dayjs(daysFilter).format('MM'),
        }
        onGetSubErrorLog(param_filter);
    }

    const handleChangeDate = (date) => {
        if (date) {
            setDateFilter(dayjs(date, 'MM/YYYY'));
        } else {
            console.log('Clear');
        }
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Cắt tín hiệu</title>
                </MetaTags>
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={12}>
                                <h6 className="page-title">Cắt tín hiệu</h6>
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">Main</li>
                                    <li className="breadcrumb-item active">Nhật ký thao tác cắt tín hiệu</li>
                                </ol>
                            </Col>
                        </Row>
                    </div>
                    {/* Table here */}
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <CardTitle className="h4">Nhật ký thao tác cắt tín hiệu</CardTitle>
                                            <p className="card-title-desc">
                                                Quản lý và ghi lại trạng thái các thuê bao đã cắt tín hiệu và cho phép ghi chú trong
                                                <span className='text-danger'> tháng {dayjs(daysFilter).format('MM')} năm {dayjs(daysFilter).format('YYYY')}</span>.
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={8}>

                                            <div className='mb-2'>
                                                <DatePicker picker="month"
                                                    placeholder='Lọc theo tháng...'
                                                    onChange={handleChangeDate}
                                                    defaultValue={daysFilter} format={'MM-YYYY'}
                                                />

                                                <ButtonAnt className='ms-1' onClick={handleFilter}><i className='fas fa-filter text-primary'></i></ButtonAnt>
                                                <Button color="success" className='ms-1' onClick={() => handleUpload()}>
                                                    <i className="fas fa-cloud-upload-alt"></i> Tải lên
                                                </Button>
                                                <Upload></Upload>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className='float-end mb-2'>
                                                <InputAnt onChange={(e) => handleSearch(e)} placeholder="search..." style={{
                                                    width: 200,
                                                }} />
                                            </div>

                                        </Col>
                                    </Row>
                                    <CutSignalTable></CutSignalTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    )
}

CutSignalImport.propTypes = {
    onOpenModalUpload: PropTypes.func,
    constraints: PropTypes.object,
    onGetSubErrorLog: PropTypes.func,
    onGetConstraints: PropTypes.func,
    onGetSubscriptions: PropTypes.func,
    filter: PropTypes.object
}

const mapStateToProps = ({ cutSignal, subscription }) => ({
    filter: cutSignal.filter,
})

const mapDispatchToProps = dispatch => ({
    onOpenModalUpload: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_UPLOAD, payload: modal }),
    onGetSubErrorLog: (filter) => dispatch(getCutSignals(filter)),
    onGetConstraints: () => dispatch(getConstraints()),
    onGetSubscriptions: () => dispatch(getSubscriptions()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CutSignalImport))
