import React, { useEffect, useState } from 'react';
import { Table, Modal, Spin } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_EDIT } from 'store/helper/actionTypes';
import {
    deleteSubscription,
    getSubscriptions
} from "../../store/actions"
import { checkCustomerStatus, cutSignalSubscription, openSignalSubscription } from 'helpers/backend_api/subscriptions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from "lodash";

const SubscriptionDeviceTable = (props) => {

    const columns = [
        {
            title: 'Mã dịch vụ',
            width: 100,
            dataIndex: 'sub_code',
            key: 'sub_code',
            fixed: 'left',
        },
        {
            title: 'Thiết bị',
            width: 100,
            dataIndex: 'device_name',
            key: 'device_name',
        },
        {
            title: 'Port user',
            width: 100,
            dataIndex: 'port',
            key: 'port',
        },
        {
            title: 'Trạng thái dịch vụ',
            width: 100,
            dataIndex: 'sub_status_name',
            key: 'sub_status_name',
        },
        {
            title: 'Ghi chú',
            width: 100,
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: 'Thao tác',
            key: 'operation',
            fixed: 'right',
            width: 80,
            render: (text, record, index) => <>
                <button type="button" onClick={() => handleOpenSignal(record)} className="btn btn-outline-info btn-sm waves-effect waves-light me-1"><i className="fas fa-key"></i></button>
                <button type="button" onClick={() => handleCutSignal(record)} className="btn btn-outline-dark btn-sm waves-effect waves-light me-1"><i className="fas fa-lock"></i></button>
                <button type="button" onClick={() => handleCheckStatusCustomer(record)} className="btn btn-outline-warning btn-sm waves-effect waves-light me-1"><i className="fas fa-plug"></i></button>
                <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
            </>,
        },
    ]

    const { subscriptions, onOpenModalEdit, onDeleteSubscription, deleteSuccess, onGetSubscriptions } = props;
    const [dataRow, setDataRow] = useState({});
    const [loading, setLoading] = useState(false);
    const [subscriptionsList, setSubscriptions] = useState([]);

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Cập nhật cổng dịch vụ khách hàng"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }

    const handleCheckStatusCustomer = (row) => {
        setLoading(true);
        checkCustomerStatus(row).then(res => {
            if (res.result === true) {
                toast.success(`Port username '${row.port}' is ${res.port_status}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
            else {
                toast.error(`Port username '${row.port}' is ${res.message}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
        });
    }

    const handleCutSignal = (row) => {
        setLoading(true);
        cutSignalSubscription(row).then(res => {
            if (res.result === true) {
                onGetSubscriptions();
                toast.success(res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
            else {
                toast.error(res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
        });
    }

    const handleOpenSignal = (row) => {
        setLoading(true);
        openSignalSubscription(row).then(res => {
            if (res.result === true) {
                onGetSubscriptions();
                toast.success(res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
            else {
                toast.error(res.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setLoading(false);
            }
        });
    }


    useEffect(() => {
        let data = [];
        subscriptions?.map(subscription => {
            data.push({
                key: subscription._id,
                id: subscription._id,
                device: subscription.device?._id,
                status: subscription.status._id,
                device_name: subscription.device?.name,
                sub_status_name: subscription.status.name,
                sub_code: subscription.sub_code,
                port: subscription.port_user,
                note: subscription.note,
            })
        });
        setSubscriptions(data)
    }, [subscriptions])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá cổng dịch vụ khách hàng "${row.port}" của mã dịch vụ "${row.sub_code}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                // console.log(message);
                onDeleteSubscription(row);
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    useEffect(() => {
        if (!_.isEmpty(deleteSuccess)) {
            toast.success(deleteSuccess.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }, [deleteSuccess])

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Edit dataRow={dataRow}></Edit>
            <Spin tip="Đang kiểm tra kết nối. Vui lòng đợi..." spinning={loading}>

                <Table
                    columns={columns}
                    dataSource={subscriptionsList}
                    scroll={{
                        x: 1500,
                        y: 500,
                    }}
                />
            </Spin>

        </>

    );

};

SubscriptionDeviceTable.propTypes = {
    t: PropTypes.any,
    onGetSubscriptions: PropTypes.func,
    subscriptions: PropTypes.array,
    onOpenModalEdit: PropTypes.func,
    onDeleteSubscription: PropTypes.func,
    deleteSuccess: PropTypes.object
}

const mapStateToProps = ({ subscription }) => ({
    subscriptions: subscription.subscriptions,
    deleteSuccess: subscription.success
})

const mapDispatchToProps = dispatch => ({
    onGetSubscriptions: () => dispatch(getSubscriptions()),
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onDeleteSubscription: (message) => dispatch(deleteSubscription(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDeviceTable); 