import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getCutSignals = (param_filter) => get(url.GET_CUT_SIGNALS + param_filter)
const updateCutSignal = (data) => post(url.UPDATE_CUT_SIGNAL, data)

export {
    getCutSignals,
    updateCutSignal
}
