import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Select as SelectAnt, Input as InputAnt, InputNumber, Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalFilter,
    getSubscriptionsByFilter,
    getSubscriptions
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap"
import { useForm } from 'react-hook-form';
import _ from "lodash";

const Filter = (props) => {
    const { modalFilter, onCloseModalFilter, constraints, onGetSubscriptionsByFilter, onGetSubscriptions } = props;

    const [fields, setFields] = useState({});
    const [myError, setMyErrors] = useState({});

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [devices, setDevices] = useState([]);
    const [statuses, setStatuses] = useState([]);
    useEffect(() => {
        if (!_.isEmpty(constraints)) {
            let deviceArr = [{ value: "", label: "== Vui lòng chọn thiết bị ==" }];
            constraints.devices.map(device => {
                deviceArr.push({ value: device._id, label: device.name })
            })
            setDevices(deviceArr);

            let statusArr = [{ value: "", label: "== Vui lòng chọn trạng thái ==" }];
            constraints.status.map(state => {
                statusArr.push({ value: state._id, label: state.name })
            })
            setStatuses(statusArr);
        }
    }, [constraints])

    const handleCancel = () => {
        onCloseModalFilter();
    };

    const handleChange = name => value => {
        let field = fields;
        field[name] = value
        setFields(field);
    }

    const onSubmit = data => {
        let data_merged = { ...data, ...fields };
        setFields({});
        if (!_.isEmpty(data_merged) && data_merged.device || data_merged.status) {
            onGetSubscriptionsByFilter(data_merged);
        } else {
            onGetSubscriptions();
        }
        onCloseModalFilter();
    };

    return (
        <>
            <Modal isOpen={modalFilter.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={handleCancel}>{modalFilter.title}</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <Label>Thiết bị</Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <SelectAnt
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={handleChange('device')}
                                            defaultValue=""
                                            style={{
                                                width: '100%',
                                            }}
                                            options={devices}
                                        />
                                        {myError.device && myError.device.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.device.message}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={4}>
                                    <Label>Trạng thái của khách hàng</Label>
                                </Col>
                                <Col md={8}>
                                    <FormGroup>
                                        <SelectAnt
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={handleChange('status')}
                                            defaultValue=""
                                            style={{
                                                width: '100%',
                                            }}
                                            options={statuses}
                                        />
                                        {myError.status && myError.status.type === "required" && (
                                            <span role="alert" className='text-danger' style={{ fontSize: "13px" }}>{myError.status.message}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Container>

                    </ModalBody>
                    <ModalFooter>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                        <ButtonAnt htmlType="submit" type="primary">
                            Xác nhận
                        </ButtonAnt>
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>

                </Form>
            </Modal>
        </>
    );
};
Filter.propTypes = {
    modalFilter: PropTypes.object,
    onCloseModalFilter: PropTypes.func,
    onGetSubscriptionsByFilter: PropTypes.func,
    onGetSubscriptions: PropTypes.func,
    constraints: PropTypes.object
}

const mapStateToProps = ({ helper, subscription }) => ({
    modalFilter: helper.modalFilter,
    constraints: subscription.constraints
})

const mapDispatchToProps = dispatch => ({
    onCloseModalFilter: () => dispatch(closeModalFilter()),
    onGetSubscriptionsByFilter: (data_merged) => dispatch(getSubscriptionsByFilter(data_merged)),
    onGetSubscriptions: () => dispatch(getSubscriptions()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Filter);