import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import {
  MODAL_CHANGE,
} from "./actionTypes"
import {
  modalChangeAction,
} from "./actions"

function* onChangeModal({ payload: properties }) {
  yield put(modalChangeAction(properties))
}

function* modalSaga() {
  yield takeEvery(MODAL_CHANGE, onChangeModal)
}

export default modalSaga
