import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Button as ButtonAnt, Timeline } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalUpload,
    getSubscriptions,
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap"
import { useForm } from 'react-hook-form';
import _ from "lodash";

const Upload = (props) => {
    const { modalUpload, onCloseModalUpload, onGetSubscriptions } = props;

    const [formError, setFormError] = useState([]);
    const [uploadStatus, setUploadStatus] = useState([]);
    const [fileUpload, setFileUpload] = useState('');

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        if (handleValidate(fileUpload)) {
            uploadFile(fileUpload)
        }
    }

    const handleCancel = () => {
        onCloseModalUpload();
    };


    const handleValidate = (value) => {
        let errors = [];
        let isSet = true;
        if (!value) {
            errors.push({
                type: "requried",
                message: "Vui lòng chọn file cần tải lên"
            });
            isSet = false;
        } else {

            if (
                value.type !== ".csv" &&
                value.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
                value.type !== "application/vnd.ms-excel"
            ) {
                isSet = false;
                errors.push({
                    type: "file format",
                    message: "File tải lên phải có định dạng, csv, xls, xlsx"
                });
            }

            if (value.size > 15728640) {
                isSet = false;
                errors.push({
                    type: "file size",
                    message: "File tải lên không được phép vượt quá 15MB"
                });
            }
        }
        setFormError(errors);
        return isSet;
    }

    const uploadFile = (file_attach) => {
        let formData = new FormData();
        if (file_attach !== null) {
            formData.append("excel_file", file_attach);
        }
        const API_URL = "https://apinoc-crm.qi.com.vn/api"
        const api = `${API_URL}/subscriptions/upload/`
        axios.post(api, formData, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authUser")}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            if (res.status === 201) {
                setUploadStatus(res.data.message);
                onGetSubscriptions();
            }
        })
    }

    const onChangeInput = (e) => {
        if (handleValidate(e.target.files[0])) {
            setFileUpload(e.target.files[0])
        }
    }

    return (
        <>
            <Modal isOpen={modalUpload.isOpenModal} toggle={handleCancel} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>

                    <ModalHeader toggle={handleCancel}>{modalUpload.title}</ModalHeader>
                    <ModalBody>
                        <Container>
                            <Timeline >
                                <Timeline.Item><b>Bước 1: </b>Tải biểu mẫu và cập nhật những nội dung tương ứng</Timeline.Item>
                                <Timeline.Item><b>Bước 2: </b>Chèn tệp đã điền đầy đủ thông tin</Timeline.Item>
                                <Timeline.Item><b>Bước 3: </b>Nhấn nút tải lên để hoàn tất</Timeline.Item>
                            </Timeline>
                            <div className='text-center'>
                                <a href='https://apinoc-crm.qi.com.vn/template_imports/subscription_customer_example.xlsx' className='btn btn-outline-success'>Tải mẫu XLSX</a>
                            </div>
                            <Row className='mb-3'>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Đính kèm tập tin XLSX</Label>
                                        <input type='file' className='form-control' name="fie_attach" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => onChangeInput(e)} />
                                        {formError.map((error, i) => (
                                            <span key={i} role="alert" style={{ fontSize: "13px", color: "#f5365c" }}>{error.message}</span>
                                        ))}
                                    </FormGroup>
                                </Col>
                            </Row>
                            {!_.isEmpty(uploadStatus) && (
                                <Row className='mb-3'>
                                    <Col md={12}>
                                        <label>Kết quả</label>
                                        <div className='table-responsive' style={{
                                            overflowY: 'auto', /* make the table scrollable if height is more than 200 px  */
                                            height: '200px'
                                        }}>
                                            <table className='table table-stripe'>
                                                <thead>
                                                    <tr>
                                                        <th>Mã dịch vụ</th>
                                                        <th>Thiết bị</th>
                                                        <th>Port user</th>
                                                        <th>Trạng thái</th>
                                                        <th>Ghi chú</th>
                                                        <th>Tình trạng</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {uploadStatus.map((state) => (
                                                        <tr key={state}>
                                                            <td>{state[0]}</td>
                                                            <td>{state[1]}</td>
                                                            <td>{state[2]}</td>
                                                            <td>{state[3]}</td>
                                                            <td>{state[4]}</td>
                                                            <td>
                                                                {state[5] !== 'Thành công' && (
                                                                    <span class="text-danger">{state[5]}</span>
                                                                )}
                                                                {state[5] === 'Thành công' && (
                                                                    <span class="text-success">{state[5]}</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </Col>
                                </Row>
                            )}

                        </Container>

                    </ModalBody>
                    <ModalFooter>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                        <ButtonAnt htmlType="submit" type="primary">
                            Tải lên
                        </ButtonAnt>
                        <ButtonAnt onClick={handleCancel}>
                            Đóng
                        </ButtonAnt>
                    </ModalFooter>

                </Form>
            </Modal>
        </>
    );
};
Upload.propTypes = {
    t: PropTypes.any,
    modalUpload: PropTypes.object,
    onCloseModalUpload: PropTypes.func,
    onGetSubscriptions: PropTypes.func
}

const mapStateToProps = ({ helper, subscription }) => ({
    modalUpload: helper.modalUpload,
})

const mapDispatchToProps = dispatch => ({
    onCloseModalUpload: () => dispatch(closeModalUpload()),
    onGetSubscriptions: () => dispatch(getSubscriptions()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Upload);