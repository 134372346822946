import axios from "axios"
import { post, del, get, put } from "../api_helper"
import * as url from "../url_helper"

const getDevices = () => get(url.GET_DEVICES)
const getDevicesByFilter = (param_filter) => get(url.GET_DEVICES + param_filter)

const addNewDevice = (data) => post(url.NEW_DEVICE, data)
const updateDevice = (data) => post(url.UPDATE_DEVICE, data)
const deleteDevice = (data) => post(url.DELETE_DEVICE, data)
const checkStatusDevice = (data) => post(url.CHECK_DEVICE_STATUS, data)

export {
    getDevices,
    getDevicesByFilter,
    addNewDevice,
    updateDevice,
    deleteDevice,
    checkStatusDevice
}
