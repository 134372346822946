import React, { useEffect, useState } from 'react';
import { Table, Modal, Tag } from 'antd';
import { queries } from '@testing-library/react';
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CustomPicker } from 'react-color';
import Edit from "./Edit"
import { CHANGE_OPEN_MODAL_EDIT } from 'store/helper/actionTypes';
// import { users } from "../../common/data/users"
import {
    deleteUser,
} from "../../store/actions"

const UserTable = (props) => {

    const columns = [
        {
            title: 'Tài khoản',
            width: 100,
            dataIndex: 'username',
            key: 'username',
            fixed: 'left',
        },
        {
            title: 'Email',
            width: 100,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Tên',
            dataIndex: 'name',
            key: 'name',
            width: 100,
        },
        {
            title: 'Admin',
            dataIndex: 'isAdmin',
            key: 'isAdmin',
            width: 50,
            render: (text, record, index) => <>
                {record.isAdmin && (
                    <Tag color="green">Kích hoạt</Tag>
                )}
                {!record.isAdmin && (
                    <Tag color="red">Chưa kích hoạt</Tag>
                )}
            </>,
        },
        {
            title: 'Thao tác',
            key: 'operation',
            fixed: 'right',
            width: 50,
            render: (text, record, index) => <>
                <button type="button" onClick={() => handleShow(record)} className="btn btn-outline-info btn-sm waves-effect waves-light me-1"><i className="fas fa-eye"></i></button>
                <button type="button" onClick={() => handleEdit(record)} className="btn btn-outline-success btn-sm waves-effect waves-light me-1"><i className="fas fa-edit"></i></button>
                <button type="button" onClick={() => handleDelete(record)} className="btn btn-outline-danger btn-sm waves-effect waves-light me-1"><i className="fas fa-trash"></i></button>
            </>,
        },
    ]

    const { users, onOpenModalEdit, onDeleteUser } = props;
    const [dataRow, setDataRow] = useState({});
    const [usersList, setUsers] = useState([]);

    const handleShow = (row) => {
        console.log(row);
    }

    const handleEdit = (row) => {
        let modal = {
            isOpenModal: true,
            title: "Cập nhật người dùng"
        }
        setDataRow(row)
        onOpenModalEdit(modal)
    }

    const handleDelete = (row) => {
        confirm(row);
    }


    useEffect(() => {
        let data = [];
        users?.map(user => {
            data.push({
                key: user.id,
                id: user.id,
                username: user.username,
                email: user.email,
                name: user.name,
                isAdmin: user.isAdmin,
            })
        });
        setUsers(data)
    }, [users])

    const confirm = (row) => {
        Modal.confirm({
            title: 'Bạn có chắc',
            icon: '',
            content: `Bạn có muốn xoá người dùng "${row.username}"`,
            okText: 'Đồng ý',
            cancelText: 'Đóng',
            onOk: () => {
                onDeleteUser(row);
            },
            okButtonProps: {
                style: {
                    backgroundColor: "#ff4d4f",
                },
            },
            centered: true
        });
    };

    return (
        <>
            <Edit dataRow={dataRow}></Edit>
            <Table
                columns={columns}
                dataSource={usersList}
                scroll={{
                    x: 1500,
                    y: 500,
                }}
            />
        </>

    );

};

UserTable.propTypes = {
    t: PropTypes.any,
    users: PropTypes.array,
    onOpenModalEdit: PropTypes.func,
    onDeleteUser: PropTypes.func
}

const mapStateToProps = ({ user }) => ({
    users: user.users,
})

const mapDispatchToProps = dispatch => ({
    onOpenModalEdit: (modal) => dispatch({ type: CHANGE_OPEN_MODAL_EDIT, payload: modal }),
    onDeleteUser: (message) => dispatch(deleteUser(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);