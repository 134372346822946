import React, { useEffect, useState } from 'react';
import { Button as ButtonAnt, Timeline, Spin } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    closeModalUpload, getCutSignals
} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Button
} from "reactstrap"
import { useForm } from 'react-hook-form';
import _ from "lodash";
import * as XLSX from 'xlsx';

const Upload = (props) => {
    const {
        modalUpload,
        onCloseModalUpload,
        subscriptions,
        constraints,
        onGetSubErrorLog,
        filter
    } = props;

    const [formError, setFormError] = useState([]);
    const [uploadStatus, setUploadStatus] = useState([]);
    const [fileUpload, setFileUpload] = useState('');
    const [dataSheet, setDataSheet] = useState([]);
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState('Đang nạp. Vui lòng chờ...');
    const [processDevicesLog, setProcessDeviceLog] = useState([]);

    const { register, handleSubmit, formState: { errors } } = useForm();

    async function postData(data) {
        try {
            const countDataSheet = data.length;
            let countItem = 0;
            const processDevices = [];

            const requests = data.map((item) => {
                const API_URL = "https://apinoc-crm.qi.com.vn/api"
                const api = `${API_URL}/cut-signals/upload/`
                axios.post(api, item, {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem("authUser")}`,
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    countItem += 1;
                    if (res.status === 200) {
                        let loadingStr = `Đang xử lý ${countItem}/${countDataSheet}. Vui lòng chờ...`;
                        setProcessing(loadingStr);
                        processDevices.push({
                            'id': item.id,
                            'name': item.name,
                            'status': res.data.result,
                            'message': res.data.message
                        });
                        setProcessDeviceLog(processDevices);
                        if (res.data.result) {
                            onGetSubErrorLog(filter);
                        }
                    }
                    if (countItem === countDataSheet) {
                        setLoading(false);
                        onCloseModalUpload();
                    }
                })
            });
            setLoading(true);
            console.log('Sending...');

            await Promise.all(requests);
        } catch (error) {
            console.error('Error posting data:', error);
        }
    }

    const onSubmit = (data) => {
        if (handleValidate(fileUpload)) {
            postData(dataSheet)
        }
    }

    const handleCancel = () => {
        onCloseModalUpload();
    };


    const handleValidate = (value) => {
        let errors = [];
        let isSet = true;
        if (!value) {
            errors.push({
                type: "requried",
                message: "Vui lòng chọn file cần tải lên"
            });
            isSet = false;
        } else {

            if (
                value.type !== ".csv" &&
                value.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
                value.type !== "application/vnd.ms-excel"
            ) {
                isSet = false;
                errors.push({
                    type: "file format",
                    message: "File tải lên phải có định dạng, csv, xls, xlsx"
                });
            }

            if (value.size > 15728640) {
                isSet = false;
                errors.push({
                    type: "file size",
                    message: "File tải lên không được phép vượt quá 15MB"
                });
            }
        }
        setFormError(errors);
        return isSet;
    }

    const onChangeInput = (event) => {
        if (handleValidate(event.target.files[0])) {
            let file = event.target.files[0];
            /* Boilerplate to set up FileReader */
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;

            reader.onload = (e) => {
                /* Parse data */
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const range = XLSX.utils.decode_range(ws['!ref']);
                range.s.r = 1;
                const rows = [];
                for (let R = range.s.r; R <= range.e.r; ++R) {
                    const cell = ws[XLSX.utils.encode_cell({ r: R, c: 0 })];
                    rows.push(cell ? cell.w : '');
                }
                sortSubByDevice(rows);
            };

            if (rABS) {
                reader.readAsBinaryString(file);
            } else {
                reader.readAsArrayBuffer(file);
            }
            setFileUpload(event.target.files[0])
        }
    }

    const sortSubByDevice = (dataSheet) => {
        const deviceSortSub = [];
        const devices = constraints.devices;
        const filteredSubs = subscriptions.filter(sub => dataSheet.includes(sub.sub_code));
        devices.map(device => {
            let sortSubByDevice = filteredSubs.filter(sub => sub.device._id === device._id);
            if (!_.isEmpty(sortSubByDevice)) {
                let dataSort = {
                    id: device._id,
                    name: device.name,
                    subs: sortSubByDevice
                }
                deviceSortSub.push(dataSort);
            }
        })
        setDataSheet(deviceSortSub);
    }

    const items = [
        { children: <div><b>Bước 1: </b>Chỉ cần có cột mã dịch vụ ở cột đầu tiên là có thể cắt tín hiệu</div> },
        { children: <div><b>Bước 2: </b>Chèn tệp đã điền đầy đủ thông tin</div> }
    ];

    return (
        <>
            <Modal isOpen={modalUpload.isOpenModal} toggle={handleCancel} backdrop={"static"} fullscreen={"lg"} size={"lg"}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Spin tip={processing} spinning={loading}>
                        <ModalHeader toggle={handleCancel}>{modalUpload.title}</ModalHeader>
                        <ModalBody>

                            <Container>
                                <Timeline items={items} />


                                <Row className='mb-3'>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Đính kèm tập tin XLSX</Label>
                                            <input type='file' className='form-control' name="fie_attach" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => onChangeInput(e)} />
                                            {formError.map((error, i) => (
                                                <span key={i} role="alert" style={{ fontSize: "13px", color: "#f5365c" }}>{error.message}</span>
                                            ))}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {!_.isEmpty(processDevicesLog) && (
                                    <Row className='mb-3'>
                                        <Col md={12}>
                                            <label>Kết quả</label>
                                            <div className='table-responsive' style={{
                                                overflowY: 'auto', /* make the table scrollable if height is more than 200 px  */
                                                height: '200px'
                                            }}>
                                                <table className='table table-stripe'>
                                                    <thead>
                                                        <tr>
                                                            <th>Thiết bị</th>
                                                            <th>Ghi chú</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {processDevicesLog.map((log) => (
                                                            <tr key={log.id}>
                                                                <td>{log.name}</td>
                                                                <td>
                                                                    {log.message}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </Col>
                                    </Row>
                                )}
                            </Container>

                        </ModalBody>
                        <ModalFooter>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                            <ButtonAnt htmlType="submit" type="primary">
                                Tải lên
                            </ButtonAnt>
                            <ButtonAnt onClick={handleCancel}>
                                Đóng
                            </ButtonAnt>
                        </ModalFooter>
                    </Spin>
                </Form>
            </Modal>
        </>
    );
};
Upload.propTypes = {
    t: PropTypes.any,
    modalUpload: PropTypes.object,
    onCloseModalUpload: PropTypes.func,
    subscriptions: PropTypes.array,
    constraints: PropTypes.object,
    onGetSubErrorLog: PropTypes.func,
    filter: PropTypes.object
}

const mapStateToProps = ({ helper, subscription, cutSignal }) => ({
    modalUpload: helper.modalUpload,
    subscriptions: subscription.subscriptions,
    constraints: subscription.constraints,
    filter: cutSignal.filter,
})

const mapDispatchToProps = dispatch => ({
    onCloseModalUpload: () => dispatch(closeModalUpload()),
    onGetSubErrorLog: (param_filter) => dispatch(getCutSignals(param_filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Upload);