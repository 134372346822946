import {
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAIL,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAIL,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAIL,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAIL,
  SET_DEVICE_FILTER
} from "./actionTypes"

const INIT_STATE = {
  devices: [],
  error: {},
  success: {},
  filter: {}
}

const device = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
      }

    case GET_DEVICES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DEVICE_SUCCESS:
      return {
        ...state,
        devices: [...state.devices, action.payload],
      }

    case ADD_DEVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_DEVICE_SUCCESS:
      return {
        ...state,
        devices: [...state.devices, action.payload],
      }

    case UPDATE_DEVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        devices: action.payload.device,
        success: action.payload.message
      }

    case DELETE_DEVICE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case SET_DEVICE_FILTER:
      return {
        ...state,
        filter: action.payload,
      }
    default:
      return state
  }
}

export default device
