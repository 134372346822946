import {
  GET_DEVICES,
  GET_DEVICES_BY_FILTER,
  GET_DEVICES_FAIL,
  GET_DEVICES_SUCCESS,
  ADD_NEW_DEVICE,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_FAIL,
  UPDATE_DEVICE,
  UPDATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_FAIL,
  DELETE_DEVICE,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAIL,
  SET_DEVICE_FILTER
} from "./actionTypes"

export const getDevices = () => ({
  type: GET_DEVICES,
})

export const getDevicesByFilter = (param_filter) => ({
  type: GET_DEVICES_BY_FILTER,
  payload: param_filter
})

export const getDevicesSuccess = devices => ({
  type: GET_DEVICES_SUCCESS,
  payload: devices,
})

export const getDevicesFail = error => ({
  type: GET_DEVICES_FAIL,
  payload: error,
})

export const addNewDevice = device => ({
  type: ADD_NEW_DEVICE,
  payload: device,
})

export const addDeviceSuccess = device => ({
  type: ADD_DEVICE_SUCCESS,
  payload: device,
})

export const addDeviceFail = error => ({
  type: ADD_DEVICE_FAIL,
  payload: error,
})

export const updateDevice = device => ({
  type: UPDATE_DEVICE,
  payload: device,
})

export const updateDeviceSuccess = device => ({
  type: UPDATE_DEVICE_SUCCESS,
  payload: device,
})

export const updateDeviceFail = error => ({
  type: UPDATE_DEVICE_FAIL,
  payload: error,
})

export const deleteDevice = device => ({
  type: DELETE_DEVICE,
  payload: device,
})

export const deleteDeviceSuccess = device => ({
  type: DELETE_DEVICE_SUCCESS,
  payload: device,
})

export const deleteDeviceFail = error => ({
  type: DELETE_DEVICE_FAIL,
  payload: error,
});

export const setDeviceFilter = param => ({
  type: SET_DEVICE_FILTER,
  payload: param,
})