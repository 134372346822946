import {
  OPEN_MODAL_ADD,
  CLOSE_MODAL_ADD,
  OPEN_MODAL_EDIT,
  CLOSE_MODAL_EDIT,
  OPEN_MODAL_DETAIL,
  CLOSE_MODAL_DETAIL,
  OPEN_MODAL_FILTER,
  CLOSE_MODAL_FILTER,
  OPEN_MODAL_UPLOAD,
  CLOSE_MODAL_UPLOAD,
} from "./actionTypes"

const INIT_STATE = {
  modalAdd: {
    isOpenModal: false,
    title: ""
  },
  modalEdit: {
    isOpenModal: false,
    title: ""
  },
  modalDetail: {
    isOpenModal: false,
    title: ""
  },
  modalFilter: {
    isOpenModal: false,
    title: ""
  },
  modalUpload: {
    isOpenModal: false,
    title: ""
  }
}

const helper = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_MODAL_ADD:
      return {
        ...state,
        modalAdd: action.payload
      }
    case CLOSE_MODAL_ADD:
      return {
        ...state,
        modalAdd: {
          isOpenModal: false,
          title: ""
        },
      }
    case OPEN_MODAL_EDIT:
      return {
        ...state,
        modalEdit: action.payload
      }
    case CLOSE_MODAL_EDIT:
      return {
        ...state,
        modalEdit: {
          isOpenModal: false,
          title: ""
        },
      }
    case OPEN_MODAL_DETAIL:
      return {
        ...state,
        modalDetail: action.payload
      }
    case CLOSE_MODAL_DETAIL:
      return {
        ...state,
        modalDetail: {
          isOpenModal: false,
          title: ""
        },
      }
    case OPEN_MODAL_FILTER:
      return {
        ...state,
        modalFilter: action.payload
      }
    case CLOSE_MODAL_FILTER:
      return {
        ...state,
        modalFilter: {
          isOpenModal: false,
          title: ""
        },
      }

    case OPEN_MODAL_UPLOAD:
      return {
        ...state,
        modalUpload: action.payload
      }
    case CLOSE_MODAL_UPLOAD:
      return {
        ...state,
        modalUpload: {
          isOpenModal: false,
          title: ""
        },
      }
    default:
      return state
  }
}

export default helper
