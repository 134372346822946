import {
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  ADD_SUBSCRIPTION_SUCCESS,
  ADD_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAIL,
  GET_CONSTRAINTS_SUCCESS,
  GET_CONSTRAINTS_FAIL,
  SET_SUBSCRIPTION_FILTER
} from "./actionTypes"

const INIT_STATE = {
  subscriptions: [],
  constraints: {},
  error: {},
  success: {},
  filter: {},
}

const subscription = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
      }

    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: [...state.subscriptions, action.payload],
      }

    case ADD_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: [...state.subscriptions, action.payload],
      }

    case UPDATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload.subscription,
        success: action.payload.message
      }

    case DELETE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CONSTRAINTS_SUCCESS:
      return {
        ...state,
        constraints: action.payload,
      }

    case GET_CONSTRAINTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SET_SUBSCRIPTION_FILTER:
      return {
        ...state,
        filter: action.payload,
      }
    default:
      return state
  }
}

export default subscription
