import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_USER,
  DELETE_USER,
  GET_USERS,
  UPDATE_USER,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser
} from "../../helpers/backend_api/users"

function renderFilterUrl(param_filter) {
  let filter = Object.keys(param_filter).map(function (key) {
    return key + '=' + param_filter[key];
  }).join('&');

  filter = '?' + filter;
  return filter;
}


function* fetchUsers({ payload: param_filter }) {
  let filter_url = renderFilterUrl(param_filter);
  const response = yield call(getUsers, filter_url)
  if (response.status === true) {
    yield put(getUsersSuccess(response.message))
  }
  else {
    yield put(getUsersFail(error))
  }
  try {

  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onAddNewUser({ payload: user }) {
  const response = yield call(addNewUser, user);
  if (response.status === true) {
    const user = yield call(getUsers, "")
    if (user.status === true) {
      yield put(getUsersSuccess(user.message))
      yield put(closeModalAdd())
    }
    else {
      yield put(getUsersFail(error))
    }
  }
}

function* onUpdateUser({ payload: user }) {
  const response = yield call(updateUser, user);
  if (response.status === true) {
    const user = yield call(getUsers, "")
    if (user.status === true) {
      yield put(getUsersSuccess(user.message))
      yield put(closeModalEdit())
    }
    else {
      yield put(getUsersFail(error))
    }
  }
}

function* onDeleteUser({ payload: user }) {
  const response = yield call(deleteUser, user);
  if (response.status === true) {
    const user = yield call(getUsers, "")
    if (user.status === true) {
      yield put(getUsersSuccess(user.message))
    }
    else {
      yield put(getUsersFail(error))
    }
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  // yield takeEvery(GET_CATEGORIES, onGetCategories)
}

export default userSaga
