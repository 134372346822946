import { takeEvery, put, call, takeLatest, select } from "redux-saga/effects"
import _ from "lodash"

// Calender Redux States
import {
  DELETE_CUT_SIGNAL,
  GET_CONSTRAINTS,
  GET_CUT_SIGNALS,
  GET_CUT_SIGNALS_BY_FILTER,
  UPDATE_CUT_SIGNAL,
} from "./actionTypes"
import {
  getCutSignalsSuccess,
  getCutSignalsFail,
  addCutSignalFail,
  addCutSignalSuccess,
  updateCutSignalSuccess,
  updateCutSignalFail,
  deleteCutSignalSuccess,
  deleteCutSignalFail,
  getConstraintsSuccess,
  getConstraintsFail,
  setCutSignalFilter
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getCutSignals,
  getCutSignalsByFilter,
  addNewCutSignal,
  updateCutSignal,
  deleteCutSignal,
  getConstraints
} from "../../helpers/backend_api/cutSignals"

function convertObjectToUrl(param_filter) {
  let filter = Object.keys(param_filter).map(function (key) {
    return key + '=' + param_filter[key];
  }).join('&');

  filter = '?' + filter;
  return filter;
}

function* fetchCutSignals({ payload: param_filter }) {
  const filterUrl = convertObjectToUrl(param_filter)
  yield put(deleteCutSignalSuccess({ message: {} }))
  try {

    const response = yield call(getCutSignals, filterUrl)
    if (response.status === true) {
      yield put(getCutSignalsSuccess(response.message))
      yield put(setCutSignalFilter(param_filter))
    }
    else {
      yield put(getCutSignalsFail(error))
    }
  } catch (error) {
    yield put(getCutSignalsFail(error))
  }
}

// function* fetchCutSignalsByFilter({ payload: param_filter }) {
//   yield put(deleteCutSignalSuccess({ message: {} }))

//   try {
//     let filter = Object.keys(param_filter).map(function (key) {
//       return key + '=' + param_filter[key];
//     }).join('&');

//     filter = '?' + filter;
//     const response = yield call(getCutSignalsByFilter, filter)
//     if (response.status === true) {
//       yield put(getCutSignalsSuccess(response.message))
//       yield put(setFilter(param_filter))
//     }
//     else {
//       yield put(getCutSignalsFail(error))
//     }
//   } catch (error) {
//     yield put(getCutSignalsFail(error))
//   }
// }

// function* fetchConstraint() {
//   try {
//     const response = yield call(getConstraints)
//     if (response.status === true) {
//       yield put(getConstraintsSuccess(response.message))
//     }
//     else {
//       yield put(getConstraintsFail(error))
//     }
//   } catch (error) {
//     yield put(getConstraintsFail(error))
//   }
// }

function* onUpdateCutSignal({ payload: cutSignal }) {
  try {
    const filter = yield select(state => state.cutSignal.filter);
    const filterUrl = convertObjectToUrl(filter)

    const response = yield call(updateCutSignal, cutSignal);
    if (response.status === true) {
      const cutSignal = yield call(getCutSignals, filterUrl)
      yield put(getCutSignalsSuccess(cutSignal.message))

      if (response.status === true) {
        yield put(closeModalEdit())
      }
      else {
        yield put(getCutSignalsFail(error))
      }
    }
  } catch (error) {
    yield put(updateCutSignalFail(error))
  }
}

// function* onDeleteCutSignal({ payload: cutSignal }) {
//   try {
//     const deleteResponse = yield call(deleteCutSignal, cutSignal);
//     if (deleteResponse.status === true) {
//       const cutSignal = yield call(getCutSignals)
//       if (cutSignal.status === true) {
//         yield put(deleteCutSignalSuccess({ message: deleteResponse, cutSignal: cutSignal.message }))
//       }
//       else {
//         yield put(deleteCutSignalFail(error))
//       }
//     }
//   } catch (error) {
//     yield put(deleteCutSignalFail(error))
//   }
// }

function* cutSignalSaga() {
  yield takeLatest(GET_CUT_SIGNALS, fetchCutSignals)
  // yield takeLatest(GET_CUT_SIGNALS_BY_FILTER, fetchCutSignalsByFilter)
  // yield takeEvery(GET_CONSTRAINTS, fetchConstraint)
  yield takeEvery(UPDATE_CUT_SIGNAL, onUpdateCutSignal)
  // yield takeEvery(DELETE_CUT_SIGNAL, onDeleteCutSignal)
}

export default cutSignalSaga
