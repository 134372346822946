import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import _ from "lodash"

// Calender Redux States
import {
  ADD_NEW_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GET_CONSTRAINTS,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_BY_FILTER,
  UPDATE_SUBSCRIPTION,
} from "./actionTypes"
import {
  getSubscriptionsSuccess,
  getSubscriptionsFail,
  addSubscriptionFail,
  addSubscriptionSuccess,
  updateSubscriptionSuccess,
  updateSubscriptionFail,
  deleteSubscriptionSuccess,
  deleteSubscriptionFail,
  getConstraintsSuccess,
  getConstraintsFail,
  setSubscriptionFilter
} from "./actions"

import {
  closeModalAdd,
  closeModalEdit,
} from "../helper/actions"

//Include Both Helper File with needed methods
import {
  getSubscriptions,
  getSubscriptionsByFilter,
  addNewSubscription,
  updateSubscription,
  deleteSubscription,
  getConstraints
} from "../../helpers/backend_api/subscriptions"

function* fetchSubscriptions() {
  yield put(deleteSubscriptionSuccess({ message: {} }))

  try {

    const response = yield call(getSubscriptions)
    if (response.status === true) {
      yield put(setSubscriptionFilter({}))
      yield put(getSubscriptionsSuccess(response.message))
    }
    else {
      yield put(getSubscriptionsFail(error))
    }
  } catch (error) {
    yield put(getSubscriptionsFail(error))
  }
}

function* fetchSubscriptionsByFilter({ payload: param_filter }) {
  yield put(deleteSubscriptionSuccess({ message: {} }))

  try {
    let filter = Object.keys(param_filter).map(function (key) {
      return key + '=' + param_filter[key];
    }).join('&');

    filter = '?' + filter;
    const response = yield call(getSubscriptionsByFilter, filter)
    if (response.status === true) {
      yield put(getSubscriptionsSuccess(response.message))
      yield put(setSubscriptionFilter(param_filter))
    }
    else {
      yield put(getSubscriptionsFail(error))
    }
  } catch (error) {
    yield put(getSubscriptionsFail(error))
  }
}

function* fetchConstraint() {
  try {
    const response = yield call(getConstraints)
    if (response.status === true) {
      yield put(getConstraintsSuccess(response.message))
    }
    else {
      yield put(getConstraintsFail(error))
    }
  } catch (error) {
    yield put(getConstraintsFail(error))
  }
}

function* onAddNewSubscription({ payload: subscription }) {
  try {
    const response = yield call(addNewSubscription, subscription);
    if (response.status === true) {
      const subscription = yield call(getSubscriptions)
      if (subscription.status === true) {
        yield put(getSubscriptionsSuccess(subscription.message))
        yield put(closeModalAdd())
      }
      else {
        yield put(getSubscriptionsFail(error))
      }
    }
  } catch (error) {
    yield put(addSubscriptionFail(error))
  }
}

function* onUpdateSubscription({ payload: subscription }) {
  try {
    const response = yield call(updateSubscription, subscription);
    if (response.status === true) {
      const subscription = yield call(getSubscriptions)
      if (subscription.status === true) {
        yield put(getSubscriptionsSuccess(subscription.message))
        yield put(closeModalEdit())
      }
      else {
        yield put(getSubscriptionsFail(error))
      }
    }
  } catch (error) {
    yield put(updateSubscriptionFail(error))
  }
}

function* onDeleteSubscription({ payload: subscription }) {
  try {
    const deleteResponse = yield call(deleteSubscription, subscription);
    if (deleteResponse.status === true) {
      const subscription = yield call(getSubscriptions)
      if (subscription.status === true) {
        yield put(deleteSubscriptionSuccess({ message: deleteResponse, subscription: subscription.message }))
      }
      else {
        yield put(deleteSubscriptionFail(error))
      }
    }
  } catch (error) {
    yield put(deleteSubscriptionFail(error))
  }
}

function* subscriptionSaga() {
  yield takeEvery(GET_SUBSCRIPTIONS, fetchSubscriptions)
  yield takeLatest(GET_SUBSCRIPTIONS_BY_FILTER, fetchSubscriptionsByFilter)
  yield takeEvery(GET_CONSTRAINTS, fetchConstraint)
  yield takeEvery(ADD_NEW_SUBSCRIPTION, onAddNewSubscription)
  yield takeEvery(UPDATE_SUBSCRIPTION, onUpdateSubscription)
  yield takeEvery(DELETE_SUBSCRIPTION, onDeleteSubscription)
}

export default subscriptionSaga
