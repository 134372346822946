import {
  GET_CUT_SIGNALS_SUCCESS,
  GET_CUT_SIGNALS_FAIL,
  UPDATE_CUT_SIGNAL_SUCCESS,
  UPDATE_CUT_SIGNAL_FAIL,
  DELETE_CUT_SIGNAL_SUCCESS,
  DELETE_CUT_SIGNAL_FAIL,
  SET_CUT_SIGNALS_FILTER
} from "./actionTypes"

const INIT_STATE = {
  cutSignals: [],
  constraints: {},
  error: {},
  success: {},
  filter: {},
}

const cutSignal = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUT_SIGNALS_SUCCESS:
      return {
        ...state,
        cutSignals: action.payload,
      }

    case GET_CUT_SIGNALS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CUT_SIGNAL_SUCCESS:
      return {
        ...state,
        cutSignals: [...state.cutSignals, action.payload],
      }

    case UPDATE_CUT_SIGNAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_CUT_SIGNAL_SUCCESS:
      return {
        ...state,
        cutSignals: action.payload.cutSignal,
        success: action.payload.message
      }

    case DELETE_CUT_SIGNAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SET_CUT_SIGNALS_FILTER:
      return {
        ...state,
        filter: action.payload,
      }
    default:
      return state
  }
}

export default cutSignal
