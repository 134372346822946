//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

export const POST_LOGIN = "/users/login/"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"


// DEVICES
export const GET_DEVICES = "/devices/"
export const NEW_DEVICE = "/devices/store/"
export const UPDATE_DEVICE = "/devices/update/"
export const DELETE_DEVICE = "/devices/delete/"
export const CHECK_DEVICE_STATUS = "/devices/check-device-status/"

// SUBSCRIPTION
export const GET_SUBSCRIPTIONS = "/subscriptions/"
export const NEW_SUBSCRIPTION = "/subscriptions/store/"
export const UPDATE_SUBSCRIPTION = "/subscriptions/update/"
export const DELETE_SUBSCRIPTION = "/subscriptions/delete/"
export const GET_CONSTRAINTS = "/subscriptions/get-constraint/"
export const CHECK_SUBSCRIPTION_STATUS = "/subscriptions/check-user-port/"
export const CUT_SUBSCRIPTION = "/subscriptions/cut-signal/"
export const OPEN_SUBSCRIPTION = "/subscriptions/open-signal/"

export const GET_CUT_SIGNALS = "/cut-signals/"
export const UPDATE_CUT_SIGNAL = "/cut-signals/update/"

// USERS
export const GET_USERS = "/users/"
export const NEW_USER = "/users/store/"
export const UPDATE_USER = "/users/update/"
export const DELETE_USER = "/users/delete/"